// plugins/shirly.js

import {
  Analytics
} from '@/firebase/analytics'

export default {
  install: (Vue) => {

    Vue.prototype.$analytics = Analytics

    // inject a globally available $translate() method
    Vue.prototype.isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    }

    Vue.filter('capitalize', function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    })

    Vue.filter('capitalizeAll', (value, join) => {
      const sentence = value.replace(/_/g , ' ');
      const words = sentence.split(" ");
      const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

      for (let i = 0; i < words.length; i++) {
          words[i] = capitalize(words[i])
      }
      return words.join(join);
    });

    Vue.prototype.$formatDisplayName = function(display_name_array, user, user_fields) {
      // Convert user_fields to an object for constant-time lookup
      const userFieldsMap = Object.fromEntries(user_fields.map(field => [field.id, field]));
    
      // Use an array to build the result
      const resultArray = [];
    
      for (let i = 0; i < display_name_array.length; i++) {
        const tag = display_name_array[i];
    
        if (tag === '"' && display_name_array[i + 2] === '"') {
          if (i > 0) resultArray.push(" ");
    
          resultArray.push(tag, user[display_name_array[i + 1]], display_name_array[i + 2]);
    
          if (i < display_name_array.length - 3) resultArray.push(" ");
    
          i += 2;
          continue;
        }
    
        const field = userFieldsMap[tag];
        if (field) {
          resultArray.push(user[tag]);
        } else {
          resultArray.push(tag);
        }
    
        if (i < display_name_array.length - 1) resultArray.push(" ");
      }
    
      // Join the array into a single string
      return resultArray.join('').trim().replace(/ \"/, '\"');;
    };
    

    Vue.mixin({
      methods: {
        async scheduledDelay(duration) {
          setTimeout(() => {
            return
          }, duration)
        },
        difference(a1, a2) {
          var a2Set = new Set(a2);
          return a1.filter(function(x) {
            return !a2Set.has(x);
          });
        },
        symmetricDifference(a1, a2) {
          return this.difference(a1, a2).concat(this.difference(a2, a1));
        },
        camelize(str) {
          return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          }).replace(/\s+/g, '');
        },
        hasKey(val, key) {
          if (Object.prototype.hasOwnProperty.call(val, key)) {
            return true
          } else {
            return false
          }
        },
        makeId(length) {
          var result = '';
          var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          var charactersLength = characters.length;
          for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
        },
        async getCustomDomainProjectId() {
          var self = this
          return new Promise((resolve, reject) => {
            async function getProjectIdByDomain(domain){

              return self.$store.dispatch('Settings/getProjectIdByDomain', { domain: domain }).then(r => {

                return r
              })
            }
            // const domain = 'http://localhost:8080'
            try {
              const domain = window.location.origin
              resolve(getProjectIdByDomain(domain))
            } catch(e){
              reject(e)
            }
          })
        },
        createRouterLinkParam(paramArray, optional_params){
          var self = this
          if(this.$customDomain){
            let formattedParamArray = paramArray.map((e) => {
                return e.toLowerCase()
            })
            return ('/' + formattedParamArray.join('/'))
          } else {
            let p_array = paramArray.filter(e => e !== 'account');
            let formattedParamArray = p_array.map((e, i) => {
              if(i === 0){
                return self.$options.filters.capitalize(e)
              } else if (i === 1) {
                return 'id'
              } else if (i === 2) {
                return 'name'
              } else if (i === 3) {
                return 'sub_name'
              } else {
                return e
              }
            })
            var param_string = formattedParamArray.join('.')
            var pathObj = new Object();
            pathObj.name = param_string
            let pT_array = paramArray.filter(e => e !== 'account');
            if(pT_array[1]){
              pathObj.params = {
                id: p_array[1].toLowerCase()
              }
            }
            if(pT_array[2]){
              pathObj.params.name = p_array[2].toLowerCase()
            }
            if(pT_array[3]){
              pathObj.params.sub_name = p_array[3].toLowerCase()
            }
            if(pathObj.name.startsWith('Admin')){
              if('params' in pathObj){
                if(pathObj.params.id === 'design'){
                  pathObj.name = 'Admin.design'
                } else {
                  let pathObjNameArray = pathObj.name.split('.')
                  pathObjNameArray.splice(1, 0, "account")
                  var newName = pathObjNameArray.join('.')
                  pathObj.name = newName
                }
              }
            }
            if(optional_params){
              pathObj.params = {...pathObj.params, ...optional_params}
            }
            return pathObj
          }
        }
      }
    })
  }
}
