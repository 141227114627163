
module.exports = {
    bulmaCssVariablesDefs: {
        'link': {
            calls: [
                {
                    fn: 'dark-color',
                    fnArg: null,
                    composeArg: null
                },
                {
                    fn: 'light-color',
                    fnArg: null,
                    composeArg: null
                }
            ]
        },
        'link-focus-border': {
            calls: []
        },
        'link-invert': {
            calls: []
        },
        'primary': {
            calls: [
                {
                    fn: 'dark-color',
                    fnArg: null,
                    composeArg: null
                },
                {
                    fn: 'light-color',
                    fnArg: null,
                    composeArg: null
                }
            ]
        },
        'primary-invert': {
            calls: []
        }
    }
};
