/* admin.store.js */
import { firestoreAction } from 'vuexfire'
import { Functions } from '@/firebase/functions'
import { DB } from '@/firebase/db'

import { ToastProgrammatic as Toast } from 'buefy'

const admin_menu = require('@/static/admin_menu.js').menu

const initialState = () => ({
  activeMenuItem: admin_menu[0],
  adminBreadcrumb: [],
  orientation: '',
  device: '',
  activeTab: 0,
  activeStep: 0,
  search: '',
  componentKey: 0,
  bulkImportColumns: [{
    'field': 'first_name',
    'label': 'first_name',
  },
  {
    'field': 'last_name',
    'label': 'last_name',
  },
  {
    'field': 'email',
    'label': 'email',
  },
  {
    'field': 'phone',
    'label': 'phone',
  }],
  bulkImportData: [{
    "first_name": "Jane",
    "last_name": "Doe",
    "email": "jdoe@example.com",
    "phone": "+1 404 555 5555"
  }],
  bulkImportDataReformatted: null,
  bulkImportErrors: [],
  bulkImportOptions: [],
  bulkImportOkayToMount: false,
  bulkImportStatus: 'EMPTY', //EMPTY, LOADING, COMPLETE
  project_id: null,
  isAppLoading: false,
  canCancelAppLoading: true,
  routerViewIsReady: false,
  isRouterModalActive: false,
  isProjectDemo: false,
  google_map: null,
  google_reference: null,
  google_map_markers: [],
  loading_status: 'INITIALIZE',
  is_app_bound: false,
  organizations: [],
  positions: [],
  field_data_type: 'user_fields'
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getActiveMenuItem: state => state.activeMenuItem,
  getAdminBreadcrumb: state => state.adminBreadcrumb,
  getOrientation: state => state.orientation,
  getDevice: state => state.device,
  getActiveTab: state => state.activeTab,
  getSearch: state => state.search,
  getComponentKey: state => state.componentKey,
  getBulkImportColumns: state => state.bulkImportColumns,
  getBulkImportData: state => state.bulkImportData,
  getBulkImportDataReformatted: state => state.bulkImportDataReformatted,
  getBulkImportErrors: state => state.bulkImportErrors,
  getBulkImportOptions: state => state.bulkImportOptions,
  getBulkImportOkayToMount: state => state.bulkImportOkayToMount,
  getBulkImportStatus: state => state.bulkImportStatus,
  getProjectId: state => state.project_id,
  getCanCancelAppLoading: state => state.canCancelAppLoading,
  getRouterViewIsReady: state => state.routerViewIsReady,
  getIsFeedbackModalActive: state => state.isFeedbackModalActive,
  getIsLoadingModalActive: state => state.isLoadingModalActive,
  getIsRouterModalActive: state => state.isRouterModalActive,
  getIsProjectDemo: state => state.isProjectDemo,
  getGoogleMap: state => state.google_map,
  getGoogleReference: state => state.google_reference,
  getGoogleMapMarkers: state => state.google_map_markers
}
// Actions
const actions = {
  reset({ commit }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  openToast: (context, payload) => {
    Toast.open(payload.toast);
  },
  bindApp: async (context) => {
    try {
      const settingsAreReady = await context.rootGetters['Router/getStatePromise']('settings_are_ready');
      if (!settingsAreReady) {
        throw new Error('Settings are not ready');
      }

      // Dispatch actions that can run in parallel
      const parallelActions = [
        context.dispatch('Stripe/bindStripeProductsForProject', {}, { root: true }),
        context.dispatch('User/bindUserRole', {}, { root: true }),
        context.dispatch('Groups/bindGroupUserField', {}, { root: true }),
        context.dispatch('Features/bindFeatures', {}, { root: true }),
        context.dispatch('Cloud/bindPresets', {}, { root: true }),
        context.dispatch('Cloudinary/bindMediaRefForProject', {}, { root: true }),
        context.dispatch('Events/bindEventsRefForProject', {}, { root: true }),
        context.dispatch('setRouterViewIsReady', true),
      ];

      // Wait for all parallel actions to complete
      await Promise.all(parallelActions);

      // Sequential actions
      const user = context.rootGetters['User/getState']('user');
      if (!user) {
        await context.dispatch('User/bindUser', {}, { root: true });
      }

      await context.dispatch('Settings/setState', { key: 'projectSettingsAreLoaded', value: true }, { root: true });
      let project = await context.rootGetters['Settings/getStatePromise']('project');
      const isDemo = project.hasOwnProperty("demo") ? project.demo : false;
      await context.dispatch('Algolia/bindAlgoliaSearchKey', { demo: isDemo }, { root: true });
      await context.dispatch('Channels/bindChannelsRef', {}, { root: true });
      await context.dispatch('Channels/bindUserChannelsRef', {}, { root: true });
      await context.dispatch('User/bindUserGroup', {}, { root: true });
      await context.dispatch('bindOrganizationsAndPositions', {});
      await context.dispatch('Stripe/bindDonationsField', {}, { root: true });
      await context.dispatch('Users/bindPublicRegistration', {}, { root: true });
      await context.dispatch('Project/bindSheets', {}, { root: true });
      await context.dispatch('Router/setState', { key: 'is_app_bound', value: true }, { root: true });
      await context.dispatch('Router/setState', { key: 'app_is_ready', value: true }, { root: true });

      return 'okay';
    } catch (e) {
      throw e; // Rethrow the error to be handled by the caller
    }
  },

  bindOrganizationsAndPositions: firestoreAction(async (context) => {
    // Bind organizations and positions
    ['organizations', 'positions'].forEach(key => {
      context.bindFirestoreRef(key, DB.collection(key));
    });
  }),

  pushGoogleMapMarker: (context, payload) => {
    context.commit('PUSH_GOOGLE_MAP_MARKER', payload)
  },
  later: async function (context, delay) {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay);
    });
  },
  setActiveMenuItem: (context, payload) => {
    context.commit('SET_ACTIVE_MENU_ITEM', payload)
  },
  setAdminBreadcrumb: (context, payload) => {
    context.commit('SET_ADMIN_BREADCRUMB', payload)
  },
  handleOrientationChange: (context, payload) => {
    context.commit('SET_ORIENTATION', payload.orientation)
  },
  handleMediaBreakpointChange: (context, payload) => {
    context.commit('SET_MEDIA_BREAKPOINT', payload.breakpoint)
  },
  setActiveTab: (context, payload) => {
    context.commit('SET_ACTIVE_TAB', payload)
  },
  setSearch: (context, payload) => {
    context.commit('SET_SEARCH', payload)
  },
  setComponentKey: (context, payload) => {
    context.commit('SET_COMPONENT_KEY', payload)
  },
  forceRerender: (context) => {
    context.dispatch('setComponentKey', context.state.componentKey++)
  },
  setBulkImportColumns: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_BULK_IMPORT_COLUMNS', payload)
      resolve({
        type: 'Success',
        message: 'Bulk import columns set in store.'
      })
    })
  },
  setBulkImportData: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_BULK_IMPORT_DATA', payload)
      resolve({
        type: 'Success',
        message: 'Bulk import data set in store.'
      })
    })
  },
  setBulkImportDataReformatted: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_BULK_IMPORT_DATA_REFORMATTED', payload)
      resolve({
        type: 'Success',
        message: 'Bulk import data (reformatted) set in store.'
      })
    })
  },
  setBulkImportErrors: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_BULK_IMPORT_ERRORS', payload)
      resolve({
        type: 'Success',
        message: 'Bulk import errors set in store.'
      })
    })
  },
  setBulkImportStatus: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_BULK_IMPORT_STATUS', payload)
      resolve({
        type: 'Success',
        message: `Bulk import status: ${payload}`
      })
    })
  },
  setBulkImportOptions: (context, payload) => {
    context.commit('SET_BULK_IMPORT_OPTIONS', payload)
  },
  setBulkImportOkayToMount: (context, payload) => {
    context.commit('SET_BULK_IMPORT_OKAY_TO_MOUNT', payload)
  },
  setProjectId: (context, payload) => {
    context.commit('SET_PROJECT_ID', payload)
  },
  doesUserEmailExist: (context, payload) => {
    const email = payload
    const doesUserEmailExist = Functions.httpsCallable("doesUserEmailExist")
    return new Promise((resolve, reject) => {
      doesUserEmailExist(email)
        .then((response) => {
          resolve(response)
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  },
  doesUserPhoneExist: (context, payload) => {
    const phone = payload
    const doesUserPhoneExist = Functions.httpsCallable("doesUserPhoneExist")
    return new Promise((resolve, reject) => {
      doesUserPhoneExist(phone)
        .then((response) => {
          resolve(response)
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  },
  setIsAppLoading: (context, payload) => {
    context.commit('SET_IS_APP_LOADING', payload)
  },
  setCanCancelAppLoading: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_CAN_CANCEL_APP_LOADING', payload)
      resolve({
        type: 'Success',
        message: `Can cancel app's loading? - ${payload}`
      })
    })
  },
  // octokitAction: (context, payload) => {
  //   const action = payload.action
  //   var data = {
  //     action: action,
  //     title: payload.title,
  //     body: payload.body
  //   }
  //   const octokitAction = Functions.httpsCallable("octokitAction")
  //     return new Promise((resolve, reject) => {
  //       octokitAction(data)
  //         .then((response) => {
  //             resolve(response)
  //         }, error => {
  //           // http failed, let the calling function know that action did not work out
  //           reject(error);
  //         })
  //     })
  // },

  setRouterViewIsReady: (context, payload) => {
    context.commit('SET_ROUTER_VIEW_IS_READY', payload)
  },

  setIsLoadingModalActive: (context, payload) => {
    context.commit('SET_IS_LOADING_MODAL_ACTIVE', payload)
  },
  setIsRouterModalActive: (context, payload) => {
    context.commit('SET_IS_ROUTER_MODAL_ACTIVE', payload)
  },
  setIsProjectDemo: (context, payload) => {
    context.commit('SET_IS_PROJECT_DEMO', payload)
  },
  setGoogleMap: (context, payload) => {
    context.commit('SET_GOOGLE_MAP', payload)
  },
  setGoogleReference: (context, payload) => {
    context.commit('SET_GOOGLE_REFERENCE', payload)
  },
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    const type = Object.prototype.hasOwnProperty.call(payload, "type") ? payload.type : 'default';
    const search_key = Object.prototype.hasOwnProperty.call(payload, "search_key") ? payload.search_key : null;
    const search_value = Object.prototype.hasOwnProperty.call(payload, "search_value") ? payload.search_value : null;

    switch (type) {
      case 'push':
        state[key].push(value)
        break;
      case 'splice':
        var removeIndex = state[key].map(item => item[search_key]).indexOf(search_value);
        ~removeIndex && state[key].splice(removeIndex, 1);
        break;
      default:
        state[key] = value
        break;
    }
  },
  SET_ACTIVE_MENU_ITEM: (state, payload) => {
    state.activeMenuItem = payload
  },
  SET_ADMIN_BREADCRUMB: (state, payload) => {
    state.adminBreadcrumb = payload
  },
  SET_ORIENTATION: (state, payload) => {
    state.orientation = payload
  },
  SET_MEDIA_BREAKPOINT: (state, payload) => {
    state.device = payload
  },
  SET_ACTIVE_TAB: (state, payload) => {
    state.activeTab = payload
  },
  SET_SEARCH: (state, payload) => {
    state.search = payload
  },
  SET_COMPONENT_KEY: (state, payload) => {
    state.componentKey = payload
  },
  SET_BULK_IMPORT_COLUMNS: (state, payload) => {
    state.bulkImportColumns = payload
  },
  SET_BULK_IMPORT_DATA: (state, payload) => {
    state.bulkImportData = payload
  },
  SET_BULK_IMPORT_DATA_REFORMATTED: (state, payload) => {
    state.bulkImportDataReformatted = payload
  },
  SET_BULK_IMPORT_ERRORS: (state, payload) => {
    state.bulkImportErrors = payload
  },
  SET_BULK_IMPORT_OPTIONS: (state, payload) => {
    state.bulkImportOptions = payload
  },
  SET_BULK_IMPORT_OKAY_TO_MOUNT: (state, payload) => {
    state.bulkImportOkayToMount = payload
  },
  SET_BULK_IMPORT_STATUS: (state, payload) => {
    state.bulkImportStatus = payload
  },
  SET_PROJECT_ID: (state, payload) => {
    state.project_id = payload
  },
  SET_IS_APP_LOADING: (state, payload) => {
    state.isAppLoading = payload
  },
  SET_CAN_CANCEL_APP_LOADING: (state, payload) => {
    state.canCancelAppLoading = payload
  },
  PUSH_GOOGLE_MAP_MARKER: (state, payload) => {
    state.google_map_markers.push(payload)
  },
  SET_ROUTER_VIEW_IS_READY: (state, payload) => {
    state.routerViewIsReady = payload
  },
  SET_IS_FEEDBACK_MODAL_ACTIVE: (state, payload) => {
    state.isFeedbackModalActive = payload
  },
  SET_IS_LOADING_MODAL_ACTIVE: (state, payload) => {
    state.isLoadingModalActive = payload
  },
  SET_IS_ROUTER_MODAL_ACTIVE: (state, payload) => {
    state.isRouterModalActive = payload
  },
  SET_IS_PROJECT_DEMO: (state, payload) => {
    state.isProjectDemo = payload;
  },
  SET_GOOGLE_MAP: (state, payload) => {
    state.google_map = payload;
  },
  SET_GOOGLE_REFERENCE: (state, payload) => {
    state.google_reference = payload;
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
