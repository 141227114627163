import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

import store from '.././store'

async function getProjectChannels() {
  if(store.getters['Settings/getProjectSettingsAreLoaded']){
    return new Promise((resolve) => {
      resolve(store.getters['Channels/getProjectChannels'])
    })
  }
}

extend("required", {
  ...required,
  message: "This field is required"
});

extend('channel_name', async(value) => {
  let channels = await getProjectChannels()
  let names =  channels.map(a => a.name)
  if (names !== null){
    if(names.indexOf(value) === -1){
      return true
    } else {
      return 'Please try another channel name.';
    }
  }
  return 'Checking server...';
});
