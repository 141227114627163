const clean = (object, clear_empty_strings) => {
  // method to remove all null and undefined object attributes
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        clean(v, clear_empty_strings)
      }
      if (clear_empty_strings){
        if ((v && typeof v === 'object' && !Object.keys(v).length) || (v === "") || (v === null) || (v === undefined)) {
          if (Array.isArray(object)) {
            object.splice(k, 1)
          } else {
            delete object[k]
          }
        }
      } else {
        if ((v && typeof v === 'object' && !Object.keys(v).length) || (v === null) || (v === undefined)) {
          if (Array.isArray(object)) {
            object.splice(k, 1)
          } else {
            delete object[k]
          }
        }
      }
    })
  return object
}
export default clean
