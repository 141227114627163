import store from '@/store'

async function run(commands) {
  // commands = ['bind_project, bind_settings, bind_app']
  return new Promise((resolve, reject) => {
    try {
      const is_app_bound = store.getters['Router/getState']('is_app_bound');
      const is_private_label = store.getters['Router/getState']('is_private_label');
      const path = store.getters['Router/getState']('path');
      const pattern = /^\/\?magic_credential=[a-zA-Z0-9%]+$/;
      const exceptions = [{
        id: 'login',
        name: 'Login',
      },
      {
        id: 'projects',
        name: 'Projects',
      },
      {
        id: 'profile',
        name: 'Profile',
      },
      {
        id: 'register',
        name: 'Register',
      }];
      switch (is_app_bound) {
        case (false): // app is not bound
          return appIsNotBound(is_private_label).then(res => {
            if (res) {
              let found_exception = false;
              exceptions.forEach((exception) => {
                if (path.toLowerCase().includes(exception.id) && !found_exception) {
                  found_exception = true;
                  bindSomething(commands).then((promises) => {
                    Promise.all(promises).then((r) => {
                      // console.log(r)
                      resolve('okay')
                    })
                  });
                  resolve(exception.id)
                } else if (pattern.test(path)) { // case: magic link login prefix
                  resolve('magic_login')
                } else {
                  resolve('continue'); // case: app is not bound; found id; not an exception (should be private_label)
                }
              })
              if (!found_exception) {
                const currentUser = store.getters['User/getState']('currentUser');
                if (currentUser) {
                  bindSomething(commands).then((promises) => {
                    Promise.all(promises).then(() => {
                      resolve('okay')
                    })
                  });
                }
              }
            } else {
              handleExceptions(exceptions, path).then((r) => {
                if (r.result) {
                  resolve(r.id)
                } else {
                  resolve('redirect'); // case: app is not bound; found id; not an exception (should be private_label)
                }
              })
            }
          }).catch(e => {
            throw e
          })
        case (true): // app is bound
          return appIsBound(is_private_label).then(res => {
            if (res) {
              const promises = bindSomething(commands);
              Promise.all(promises).then(() => {
                resolve('okay')
              })
            } else {
              let found_exception = false;
              exceptions.forEach((exception) => {
                if (path.toLowerCase().includes(exception.id) && !found_exception) {
                  found_exception = true;
                  resolve(exception.id)
                } else {
                  resolve('unable to bind (false)');
                }
              })
            }
          }).catch(e => {
            throw e
          })
        default:
          return appIsNotBound(is_private_label).then(res => {
            if (res) {
              const promises = bindSomething(commands);
              Promise.all(promises).then(() => {
                resolve('okay')
              })
            } else {
              let found_exception = false;
              exceptions.forEach((exception) => {
                if (path.toLowerCase().includes(exception.id) && !found_exception) {
                  found_exception = true;
                  resolve(exception.id)
                } else {
                  resolve('unable to bind (false)');
                }
              })
            }
          }).catch(e => {
            throw e
          })
      }
    } catch (e) {
      reject(e)
    }
  })
}

async function appIsBound(is_private_label) {
  const project_id = store.getters['Router/getState']('project_id');
  const path = store.getters['Router/getState']('path');
  return new Promise((resolve) => {
    switch (is_private_label) {
      case (true):
        resolve(true) // is a private label
        break;
      case (false):
        // is a white label
        // check to make sure that suffix has not changed (if so, need to rebind the app by running the function again after setting the store)
        getWhiteLabelProjectId(path).then(res => {
          const path_project_id = res.project_id;
          if (path_project_id === project_id) {
            resolve(true)
          } else {
            store.dispatch('Router/setState', { key: 'is_app_bound', value: false }).then(() => {
              resolve(false)
            })
          }
        })
        break;
      default:
        // is a white label
        // check to make sure that suffix has not changed (if so, need to rebind the app)
        getWhiteLabelProjectId(path).then(res => {
          const path_project_id = res.project_id;
          if (path_project_id === project_id) {
            resolve(true)
          } else {
            store.dispatch('Router/setState', { key: 'is_app_bound', value: false }).then(() => {
              resolve(false)
            })
          }
        })
        break;
    }
  })
}

async function appIsNotBound(is_private_label) {
  const domain = store.getters['Router/getState']('domain');
  const path = store.getters['Router/getState']('path');
  return new Promise((resolve) => {
    switch (is_private_label) {
      case (true):
        // is a private label
        getPrivateLabelProjectId(domain).then(res => {
          const path_project_id = res.project_id;
          if (path_project_id !== null) {
            store.dispatch('Router/setState', { key: 'project_id', value: path_project_id });
            store.dispatch('Admin/setState', { key: 'project_id', value: path_project_id });
            resolve(true)
          } else {
            resolve(false)
          }
        })
        break;
      case (false):
        // is a white label
        getWhiteLabelProjectId(path).then(res => {
          const path_project_id = res.project_id;
          const path_suffix = res.suffix;
          if (path_project_id) {
            store.dispatch('Router/setState', { key: 'project_id', value: path_project_id });
            store.dispatch('Admin/setState', { key: 'project_id', value: path_project_id });
            resolve(true)
          } else if (path_suffix) {
            resolve(false)
          } else {
            resolve(false)
          }
        })
        break;
      default:
        // same as false
        getWhiteLabelProjectId(path).then(res => {
          const path_project_id = res.project_id;
          if (path_project_id) {
            resolve(false)
          } else {
            resolve(false)
          }
        })
        break;
    }
  })
}

async function getPrivateLabelProjectId(domain) {
  return new Promise((resolve, reject) => {
    // const domain = 'http://localhost:8080'
    try {
      store.dispatch('Settings/getProjectIdByDomain', {
        domain: domain
      }).then(r => {
        resolve({
          project_id: r,
          domain: domain
        })
      }).catch(() => {
        throw 'Error. Could not get private label domain.'
      })
    } catch (e) {
      reject(e);
    }
  })
}

async function getWhiteLabelProjectId(path) {
  return new Promise((resolve, reject) => {
    try {
      const split_path = path.split('/');
      if (split_path.length >= 2) { // would either be https://app.shirly.com/tfoa || http://localhost:8080/tfoa
        let project_id = split_path[1];
        if (path === '/') {
          resolve({
            suffix: 'projects',
            path: path
          })
          return;
        }
        switch (split_path[1]) {
          case ('login'):
          case ('projects'):
          case ('profile'):
          case ('register'):
            resolve({
              suffix: split_path[1],
              path: path
            })
            break;
          default:
            if (store.getters['Router/getState']('project_id')) {
              resolve({
                project_id: project_id,
                path: path
              })
            } else {
              resolve({
                project_id: split_path[1],
                path: path
              })
              break;
            }
        }
      } else {
        throw 'Error. Could not get white label path.'
      }
    } catch (e) {
      reject(e);
    }
  })
}

async function bindSomething(commands) {
  return new Promise((resolve) => {
    let promises = [];
    commands.map((command, index) => {
      let decision = command ? index : null;
      switch (decision) {
        case (0):
          promises.push(store.dispatch('Router/setState', { key: 'project_is_ready', value: true }));
          promises.push(bindProject());
          break;
        case (1):
          promises.push(store.dispatch('Router/setState', { key: 'settings_are_ready', value: true }));
          promises.push(bindSettings());
          break;
        case (2):
          promises.push(store.dispatch('Router/setState', { key: 'app_is_ready', value: true }));
          promises.push(bindApp());
          break;
        default:
          return null;
      }
    })
    resolve(promises)
  })
}

async function handleExceptions(exceptions, path) {
  return new Promise((resolve) => {
    let d = {
      result: false,
      id: null
    };
    for (let i = 0; i < exceptions.length; i++) {
      let exception = exceptions[i];
      let path_array = path.toLowerCase().split('/');
      let exception_id = exception.id;
      let decision = path_array.includes(exception_id);
      if (decision) {
        d.result = true;
        d.id = exception_id;
        break;
      }
    }
    resolve(d)
  });
}

async function bindSettings() {
  return store.dispatch('Settings/bindSettings');
}

async function bindProject() {
  const project_id = await store.getters['Router/getState']('project_id');
  return store.dispatch('Settings/bindProject', project_id)
}

async function bindApp() {
  return store.dispatch('Admin/bindApp');
}

export default run;
