/* features.store.js */
import { firestoreAction } from 'vuexfire'
import { DB } from '@/firebase/db'

const initialState = () => ({
  features: []
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  }
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('okay');
    })
  },
  bindFeatures: firestoreAction(async (context) => {
    const project_id = context.rootGetters['Router/getState']('project_id');
    await context.bindFirestoreRef('features', DB.collection('projects').doc(project_id).collection('features'));
  }),
  resetState: (context) => {
    return new Promise((resolve) => {
      context.commit('RESET');
      resolve('Okay');
    })
  },
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
