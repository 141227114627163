/* settings.store.js */
import Vue from 'vue'
import { firestoreAction } from 'vuexfire'
import Firebase from 'firebase/compat/app'
import { Functions } from '@/firebase/functions'
import { DB } from '@/firebase/db'
import { sort } from 'fast-sort'

// import clean from './plugins/clean'
const footer_init = require('@/static/footer_init.js').data;

const initialState = () => ({
  // Cloud settings
  project: {
    info: {
      project_color: '#485fc7'
    }
  },
  project_image: null,
  // projectSettingsAreLoaded: false,
  users: [],
  navigation: null,
  theme: null,
  sections: null,
  content: [],
  footer: footer_init,
  photos: [],
  logos: [],
  navigation_logo_public_id: `${window.location.origin}/favicon.ico`,
  profile_images_tmp: [],
  user_fields: [],
  user_fields_project: [],
  display_name: [],
  organizations: [],
  positions: [],
  secrets: [],
  settings_loaded: false,
  primary_color: '#485fc7',
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getProject: state => state.project,
  // getProjectSettingsAreLoaded: state => state.projectSettingsAreLoaded,
  getUsers: state => state.users, // MOVE THIS TO ADMIN
  getNavigation: state => state.navigation,
  getHeader: state => state.header,
  getTheme: state => state.theme,
  getContent: state => state.content,
  getFooter: state => state.footer,
  getPhotos: state => state.photos,
  getLogos: state => state.logos,
  getDisplayName: state => state.display_name,
  getSettingsLoaded: state => state.settings_loaded,
  getProfileImagesTmp: state => state.profile_images_tmp,
  getUserFields: state => state.user_fields,
  getZoomSizes: state => {
    return state.theme && Object.prototype.hasOwnProperty.call(state.theme, 'data') && Object.prototype.hasOwnProperty.call(state.theme.data, 'zoom') ? state.theme.data.zoom.sizes : null;
  },
  getPrimaryColor: state => {
    return state.theme && Object.prototype.hasOwnProperty.call(state.theme, 'data') && Object.prototype.hasOwnProperty.call(state.theme.data, 'color') ? state.theme.data.color.color : null;
  },
  getDisplayNameArray: state => {
    return state.display_name && Object.prototype.hasOwnProperty.call(state.display_name, 'array') ? state.display_name.array : [];
  },
  getNavigationById: (state) => (id) => {
    return state.navigation.find(item => item.id === id)
  },
  getCloudinaryPresetByKey: (state) => (id) => {
    Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));
    // if(Object.prototype.hasOwnProperty.call(state.project, "cloudinary")){
    var cloudinary = state.project.cloudinary;
    var filtered = Object.filter(cloudinary, ([key]) => (key === id));
    return filtered
  },
  getSecretsById: (state) => (id) => {
    return state.secrets.find(item => item.id === id)
  },
}
// Actions
const actions = {
  resetState: (context) => {
    return new Promise((resolve) => {
      context.commit('RESET');
      resolve('Okay');
    })
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  setAllFirebaseDemo({
    commit
  }, payload) {
    commit('SET_ALL_FIREBASE_DEMO', payload)
  },
  bindSettings: firestoreAction(async (context) => {
    try {
      const projectId = context.rootGetters['Router/getState']('project_id');
      if (!projectId) {
        throw new Error('Project ID is not available');
      }

      // Bind users and secrets
      const collectionsToBind = ['users', 'secrets', 'organizations', 'positions'];
      const settingsCollectionsToBind = ['theme', 'navigation', 'footer', 'content', 'display_name'];

      // Start binding Firestore references in parallel
      const bindingPromises = collectionsToBind.map(key =>
        context.bindFirestoreRef(key, DB.collection('projects').doc(projectId).collection(key))
      );

      // Bind settings collections with a specific type
      settingsCollectionsToBind.forEach(key => {
        bindingPromises.push(
          context.bindFirestoreRef(key, DB.collection('projects').doc(projectId).collection('settings').where("type", "==", key))
        );
      });

      // Dispatch actions to bind additional settings
      bindingPromises.push(context.dispatch('bindUserFields', { project_id: projectId }));
      bindingPromises.push(context.dispatch('Users/bindPublicRegistrationFields', { project_id: projectId }, { root: true }));
      bindingPromises.push(context.dispatch('Users/bindPublicRegistrationApplicants', { project_id: projectId }, { root: true }));
      bindingPromises.push(context.dispatch('Project/bindState', { key: 'project_secrets', path: `projects/${projectId}/secrets` }, { root: true }));
      bindingPromises.push(context.dispatch('bindSections'));

      // Fetch project image and color in parallel
      const projectDoc = await DB.collection('projects').doc(projectId).get();
      if (projectDoc.exists) {
        const { project_image, project_color } = projectDoc.data().info || {};
        if (project_image) {
          context.commit('SET_STATE', { key: 'project_image', value: project_image });
        }
        if (project_color) {
          context.commit('SET_STATE', { key: 'primary_color', value: project_color });
        }
      }

      // Wait for all bindings and dispatches to complete
      await Promise.all(bindingPromises);

      // Once everything is bound and dispatched, update the state
      context.commit('SET_STATE', { key: 'settings_loaded', value: true });

      return true; // Resolve the promise with a boolean indicating success
    } catch (error) {
      // Handle the error appropriately
      console.error('Failed to bind settings:', error);
      // Depending on your error handling strategy, you might want to:
      // - Commit a mutation to update the state with the error
      // - Throw the error to be handled by the caller
      // - Return a specific error code or message
      throw error; // Rethrow the error if you want the caller to handle it
    }
  }),

  override: async (context) => {
    let override_items = ['footer'];
    override_items.forEach(async (item) => {
      const { data } = await import(`@/static/${item}_init.js`);
      context.commit('SET_STATE', { key: item, value: {} });
      if (item === 'theme') {
        context.commit('SET_STATE', { key: 'zoom_sizes', value: data.zoom.sizes })
      }
      if (item === 'content') {
        let sections = require('@/static/section_init.js').items;
        context.commit('SET_STATE', { key: 'sections', value: sections })
      }
    })
  },
  bindSections: firestoreAction(async (context) => {
    var project_id = context.rootGetters['Router/getState']('project_id');
    context.bindFirestoreRef('sections', DB.collection('projects').doc(project_id).collection('settings').doc('content').collection('items').orderBy("position", "asc"))
  }),
  bindUserFields: firestoreAction(async (context, payload) => {
    const { project_id } = payload
    await context.bindFirestoreRef('user_fields', DB.collection('projects').doc(project_id).collection('user_fields').orderBy('position', 'asc'));
    await context.bindFirestoreRef('user_fields_project', DB.collection('projects').doc(project_id).collection('user_fields').where("project", "==", true));
  }),
  bindProject: async (context) => {
    var project_id = context.rootGetters['Router/getState']('project_id');
    if (project_id) {
      const project = await context.dispatch('Project/bindState', { key: 'project', path: `projects/${project_id}` }, { root: true });
      return new Promise((resolve, reject) => {
        try {
          if (project) {
            context.commit('SET_STATE', { key: 'project', value: project })
            resolve('okay')
          } else {
            throw 'Error. Could not bind project.'
          }
        } catch (e) {
          console.error(e);
          reject(e);
        }
      })
    }
  },
  bindSettingsRefForProject: firestoreAction(async (context, payload) => {
    // context contains all original properties like commit, state, etc
    // and adds `bindFirestoreRef` and `unbindFirestoreRef`
    // we return the promise returned by `bindFirestoreRef` that will
    // resolve once data is ready
    async function getAllProjectSettings() {
      return new Promise((resolve) => {
        var project_id = payload.project_id
        Object.keys(state).forEach(async (key) => {
          if (key === 'users' || key === 'secrets') {
            context.bindFirestoreRef(key, DB.collection('projects').doc(project_id).collection(key))
          } else if (key === 'navigation_logo_public_id') {
            DB.collection('projects').doc(project_id).collection('settings').doc('navigation').collection('items').doc('icon').get().then((doc) => {
              if (doc.exists) {
                if (Object.prototype.hasOwnProperty.call(doc.data(), "images")) {
                  context.commit('SET_NAVIGATION_LOGO_PUBLIC_ID', doc.data()['images'][0].public_id)
                }
              }
            })
          } else if (key === 'project') {
            const doc = await DB.collection('projects').doc(project_id).get();
            if (doc.exists) {
              let project = doc.data();
              project.id = doc.id;
              context.commit('SET_STATE', { key: 'project', value: project })
            }
          } else if (key === 'user_fields') {
            context.bindFirestoreRef(key, DB.collection('projects').doc(project_id).collection('settings').doc(key).collection('items').orderBy("position", "asc"))
          } else {
            context.bindFirestoreRef(key, DB.collection('projects').doc(project_id).collection('settings').doc(key).collection('items'))
          }
        });
        resolve(true)
      })
    }
    return getAllProjectSettings()
  }),
  checkIfIdExists: firestoreAction((context, payload) => {
    var project_id = payload.project_id
    return new Promise((resolve, reject) => {
      const docRef = DB.collection("domains").doc(project_id)
      return docRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            docRef.onSnapshot((doc) => {
              resolve(doc.data())
            });
          } else {
            reject(null)
          }
        });
    })
  }),
  getCloudinaryPresetByKeyAction: firestoreAction((context, payload) => {
    return context.getters.getCloudinaryPresetByKey(payload)
  }),
  getProjectIdByDomain: firestoreAction((context, payload) => {
    // context contains all original properties like commit, state, etc
    // and adds `bindFirestoreRef` and `unbindFirestoreRef`
    // we return the promise returned by `bindFirestoreRef` that will
    // resolve once data is ready
    var domain = payload.domain
    return new Promise((resolve, reject) => {
      DB.collection("domains").where("domains", 'array-contains', domain).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            resolve(doc.id)
          });
        }).catch((error) => {
          reject(error)
        });
    })
  }),
  addDocument: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let parent = payload.parent
    let id = payload.id

    let data = payload.data
    return new Promise((resolve, reject) => {
      DB.collection('projects').doc(project_id).collection('settings').doc(parent).collection('items').doc(id).set(data)
        .then(() => {
          var msg = 'Okay'
          resolve(msg);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  updateSettings: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let setting_id = payload.parent
    // let p = clean(payload)
    var data = {
      project_id: project_id,
      setting_id: setting_id,
      payload: payload
    }
    const updateSettings = Functions.httpsCallable("updateSettings")
    return new Promise((resolve, reject) => {
      return updateSettings(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  updateProject: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    // let p = clean(payload)
    var data = {
      project_id: project_id,
      payload: payload
    }
    const updateProject = Functions.httpsCallable("updateProject")
    return new Promise((resolve, reject) => {
      return updateProject(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  setSecrets: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let secret_id = payload.secret_id
    // let p = clean(payload)
    var data = {
      project_id: project_id,
      secret_id: secret_id,
      payload: payload
    }
    const setSecrets = Functions.httpsCallable("setSecrets")
    return new Promise((resolve, reject) => {
      return setSecrets(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  resetSettings: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let setting_id = payload
    var data = {
      project_id: project_id,
      setting_id: setting_id,
    }
    const resetSettings = Functions.httpsCallable("resetSettings")
    return new Promise((resolve, reject) => {
      return resetSettings(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  createUploadTemplate: firestoreAction((context, data) => {
    const createUploadTemplate = Functions.httpsCallable("createUploadTemplate")
    return new Promise((resolve, reject) => {
      createUploadTemplate(data)
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  retrieveSheetValues: firestoreAction((context, data) => { // MOVE THIS TO ADMIN
    const retrieveSheetValues = Functions.httpsCallable("retrieveSheetValues")
    return new Promise((resolve, reject) => {
      retrieveSheetValues(data)
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  removeImageField: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let parent = payload.parent
    let id = payload.id
    var itemsRef = DB.collection('projects').doc(project_id).collection('settings').doc(parent).collection('items').doc(id)
    itemsRef.update({
      image: []
    }).then((r) => {
      console.log(r)
      // console.log('Document updated.')
    })
  }),
  atomicallyupdateSettingsArray: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let parent = payload.parent
    let id = payload.id
    let searchObj = payload.searchObj
    // let searchField = payload.searchField
    const data = {
      image: Firebase.firestore.FieldValue.arrayRemove(searchObj)
    }
    return new Promise((resolve, reject) => {
      DB.collection('projects').doc(project_id).collection('settings').doc(parent).collection('items').doc(id).update(data)
        .then(() => {
          var msg = 'Atomically updated.'
          resolve(msg);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  removeDocument: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let parent = payload.parent
    let id = payload.id
    return new Promise((resolve, reject) => {
      DB.collection('projects').doc(project_id).collection('settings').doc(parent).collection('items').doc(id).delete()
        .then(() => {
          var msg = 'Document removed.'
          resolve(msg);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  searchAndRemoveReferences: firestoreAction((context, payload) => {
    let project_id = context.rootState.Admin.project_id
    let parent = payload.parent
    let searchObj = payload.searchObj
    let searchField = payload.searchField
    var itemsRef = null
    if (parent === 'navigation') {
      return new Promise((resolve, reject) => {
        itemsRef = DB.collection('projects').doc(project_id).collection('settings').doc('navigation').collection('items').doc('icon')
        itemsRef.update({
          image: []
        }).then(() => {
          var msg = 'Removed navigation icon.'
          resolve(msg);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        try {
          itemsRef = DB.collection('projects').doc(project_id).collection('settings').doc(parent).collection('items')
          return itemsRef.where(searchField, "array-contains", searchObj)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                let docId = doc.id
                var payload_alt = {
                  parent: parent,
                  id: docId,
                  searchObj: searchObj,
                  searchField: searchField
                }
                return context.dispatch('atomicallyupdateSettingsArray', payload_alt).then((response) => {
                  resolve(response);
                })
              });
            })
            .catch((error) => {
              reject('p', error);
            });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })
    }
  }),
  // renameCloudinaryImage: firestoreAction(async (context, data) => { // MOVE THIS TO ADMIN
  //   const renameImage = Functions.httpsCallable("renameImage")
  //   return new Promise((resolve, reject) => {
  //     renameImage(data)
  //       .then((response) => {
  //         resolve(response);
  //       }, error => {
  //         console.log(error)
  //         // http failed, let the calling function know that action did not work out
  //         reject(error);
  //       })
  //   })
  // }),
  removeCloudinaryPhoto: async function (context, payload) {
    var fullRemove = true
    let publicId = payload.public_id
    if ('fullRemove' in payload) {
      fullRemove = payload.fullRemove
    } else {
      let searchObj = payload.searchObj
      let searchField = payload.searchField
      let id = ''
      if (payload.imgType === 'photos' || payload.imgType === 'logos') {
        id = payload.public_id.split('/').slice(-1)[0]
      } else {
        id = payload.public_id
      }
      var payload_alt_rmd = {
        parent: payload.imgType,
        id: id,
        searchObj: searchObj,
        searchField: searchField
      }
    }
    const result = await context.dispatch('Functions/callFunction', { function_name: 'callCloudinaryAction', function_payload: { action: 'destroy_image', public_id: publicId } }, { root: true });
    if (result.data.result === 'ok' && fullRemove) {
      return new Promise((resolve, reject) => {
        return context.dispatch('removeDocument', payload_alt_rmd).then((response) => {
          if (response === 'Document removed.') {
            if (fullRemove) {
              var payload_alt_sarr = Object.assign({}, payload_alt_rmd)
              payload_alt_sarr.parent = payload.parent
              context.dispatch('searchAndRemoveReferences', payload_alt_sarr).then((response) => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              });
            } else {
              resolve(response);
            }
          }
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
      })
    } else {
      // console.log(result.data.result)
    }
  },
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATE: (state, payload) => {
    const keys = payload.key.split(".");
    const value = payload.value;

    let current = state;
    for (let i = 0; i < keys.length; i++) {
      // If it's the last key, set the value
      if (i === keys.length - 1) {
        current[keys[i]] = value;
      } else {
        // If the key doesn't exist in the state, create it as an empty object
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }

        // Move our current position in the state object down one level
        current = current[keys[i]];
      }
    }
    // console.log('key', payload.key, 'value', value)
  },
  VUE_SET_STATE: (state, payload) => {
    const array = payload.array;
    const key = payload.key;
    const value = payload.value;
    Vue.set(array, key, value)
  },
  SET_PROJECT: (state, payload) => {
    state.project = payload
  },
  SET_NAVIGATION_LOGO_PUBLIC_ID: (state, payload) => {
    state.navigation_logo_public_id = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
