/* cloud[inary].store.js */
import { DB } from '@/firebase/db'
import { firestoreAction } from 'vuexfire'
// State initialization
const state = {
  widget: null,
  signature: null,
  timestamp: null,
  presets: [],
  preset: null,
  upload_button_visible: true,
  callback: true
};

// Define getters for state properties
const getters = {
  getState: state => key => state[key],
};

// Define actions
const actions = {
  // Commit payload to state
  setState({ commit }, payload) {
    commit('SET_STATE', payload);
    return Promise.resolve('Okay');
  },
  bindPresets: firestoreAction(async (context) => {
    const project_id = context.rootGetters['Router/getState']('project_id');
    context.bindFirestoreRef('presets', DB.collection('projects').doc(project_id).collection('cloudinary'));
  }),

  // Initialize upload widget
  async initUploadWidget({ commit, dispatch, getters }, { type, queue_path, ref_doc_path, bindAction, callback = true, additional_params = {} }) {
    try {
      // console.log(additional_params); // It will log an empty object if additional_params is not provided
      const preset = await dispatch('getPreset', type);
      await dispatch('getSignature', preset);

      // Params for cloudinary widget
      const params = {
        apiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: getters.getState('preset'),
        uploadSignatureTimestamp: getters.getState('timestamp'),
        uploadSignature: getters.getState('signature'),
      };

      // Wrap Cloudinary widget creation in a Promise
      return new Promise((resolve, reject) => {
        const widget = window.cloudinary.createUploadWidget(params, (error, result) => {
          if (!error && result && result.event === 'success') {
            if (callback) {
              resolve(dispatch('defaultCallback', { image: result.info, queue_path, ref_doc_path, bindAction, additional_params }));
            } else {
              resolve(result.info); // Resolve the Promise with the result.info
            }
          } else if (error) {
            console.error('Error uploading: ', error);
            reject(error); // Reject the Promise if there's an error
          } else if (result && result.event === 'close') {
            // Handle the 'close' event
            resolve(null); // Resolve the Promise with null to signify no action
          }
        });

        commit('SET_STATE', { key: 'widget', value: widget });
        dispatch('openUploadWidget');
      });
    } catch (error) {
      console.error('An error occurred during widget initialization:', error);
      throw error; // Propagate the error up the call chain
    }
  },


  // Get signature
  async getSignature({ commit, dispatch }, preset) {
    try {
      const response = await dispatch('Functions/callFunction', { function_name: 'projectMediaOnCallCloudinarySwitch', function_payload: { action: 'generate_signature', params: { upload_preset: preset } } }, { root: true });
      commit('SET_STATE', { key: 'signature', value: response.signature });
      commit('SET_STATE', { key: 'timestamp', value: response.timestamp });
      return response;
    } catch (error) {
      console.error('Error getting signature: ', error);
      throw error;
    }
  },

  // Get preset
  async getPreset({ commit, rootGetters, state }, type) {
    console.log('type', type)
    try {
      if (!state.preset) {
        if (type === 'shirly_profile_images') {
          commit('SET_STATE', { key: 'preset', value: 'shirly_profile_images_preset' });
          return 'shirly_profile_images_preset';
        } else {
          const presets = state.presets;
          const preset = presets.find(preset => preset.id === `${type}_preset`);
          commit('SET_STATE', { key: 'preset', value: preset.name });
          return preset.name;
        }
      } else {
        return state.preset;
      }
    } catch (error) {
      console.error('Error getting preset: ', error);
      throw error;
    }
  },

  async bindAction({ dispatch }, params) {
    const { action_path, action_payload } = params;
    try {
      await dispatch(action_path, action_payload, { root: true });
    } catch (error) {
      console.error('Error with bind action event: ', error);
      throw error;
    }
  },

  // Default callback
  async defaultCallback({ dispatch }, { image, queue_path, ref_doc_path, bindAction, additional_params }) {
    const res = await dispatch('Functions/callFunction', {
      function_name: 'firekitOnCallFirestoreSwitch',
      function_payload: {
        action: "add", path: queue_path, payload: {
          state: "NEW",
          ref_doc_path: `${ref_doc_path}/${image.public_id.split("/").pop()}`,
          action: 'firestore_switch',
          subaction: 'set',
          payload: { ...image, ...additional_params }
        }
      }
    }, { root: true });
    if (bindAction) {
      return setTimeout(() => {
        dispatch('bindAction', bindAction);
      }, 1000)
    } else {
      return res.data.payload
    }
  }
  ,

  // Open upload widget
  openUploadWidget({ state }) {
    state.widget.open();
  },
};

// Define mutations
const mutations = {
  // Set a state property
  SET_STATE(state, { key, value }) { state[key] = value; },
  // Reset state to initial state
  RESET(state) { Object.assign(state, { widget: null, signature: null, timestamp: null, preset: null, }); },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
