/* Core */
import Vue from 'vue'
import '@/main.scss'
import Buefy from 'buefy'

/* Routers & Store */
import { Router } from '@/router/index'
import store from './store'

/* Shirly */
import ShirlyPlugin from './plugins/shirly';

/* Firebase */
import { Auth } from './firebase/auth'

/* Validation */
import "./services/vee-validate";

/* VueHtml2Canvas */
import VueHtml2Canvas from 'vue-html2canvas';

/* Cloudinary */
import Cloudinary from 'cloudinary-vue';

/* VueMeta */
import VueMeta from 'vue-meta'

/* Algolia Vue Instant Search */
import InstantSearch from 'vue-instantsearch';

/* Regenerator Runtime */
import 'regenerator-runtime';

/* Vue Observe Visibility */
import VueObserveVisibility from 'vue-observe-visibility'

/* Service Worker */
// import './registerServiceWorker'

//
// Require Froala Editor css files.
// Import and use Vue Froala lib.
// import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
// import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/link.min.js'
// import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/link.min.js'

import VueFroala from 'vue-froala-wysiwyg'

/* Vue. Main component */
import App from './App.vue'

/* Vuejs Bus */
export const bus = new Vue()
//
import {
  DomainType
} from './router/index'
Vue.prototype.$hostname = window.location.origin;
Vue.prototype.$customDomain = DomainType.isCustomDomain;

// Tell Vue.js to use Buefy
Vue.use(Buefy)

// Tell Vue.js to use VueHtml2Canvas
Vue.use(VueHtml2Canvas);

// Tell Vue.js to use custom Shirly pluginn
Vue.use(ShirlyPlugin)

// Tell Vue.js to use VueMeta
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

// Tell Vue.js to use InstantSearch
Vue.use(InstantSearch);

// Tell Vue.js to use VueObserveVisibility
Vue.use(VueObserveVisibility)

// Tell Vue.js to use Cloudinary
Vue.use(Cloudinary, {
  configuration: {
    cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME
  }
});

Vue.use(VueFroala)

// Wrap the vue instance in a Firebase onAuthStateChanged method
// This stops the execution of the navigation guard 'beforeEach'
// method until the Firebase in itialization ends

let app = '';
// let router = '';
let analytics = Vue.prototype.$analytics

store.dispatch('Admin/setIsLoadingModalActive', true)

Auth.onAuthStateChanged(async (user) => {
  if (user) {
    analytics.setUserId(user.uid);
    analytics.setUserProperties({
      account_type: "Basic" // can help you to define audiences
    });
  }
  if (!app) {
    // router = await import('./router/index');
    app = new Vue({
      router: Router,
      store,
      render: h => h(App)
    }).$mount("#app");
    // const router = await import('./router/index')
    // app.options.router = router
  }
});
