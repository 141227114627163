/* channels.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  DB
} from '@/firebase/db'

// import the auto exporter

const initialState = () => ({
  project_channels: [],
  user_project_channels: [],
  show_channel_form: false,
  edit_channel_id: null
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindChannelsRef: firestoreAction(async (context) => {
    let project_id = context.rootState.Admin.project_id
    return context.bindFirestoreRef('project_channels', DB.collection('projects').doc(project_id).collection('channels'))
  }),
  bindUserChannelsRef: firestoreAction(async (context) => {
    const project_id = context.rootState.Admin.project_id;
    const user_id = context.rootState.User.currentUser.uid;

    return context.bindFirestoreRef('user_project_channels',
      DB.collection('projects')
        .doc(project_id)
        .collection('channels')
        .where('members', 'array-contains', user_id)
    );
  }),
  bindState: firestoreAction(async ({ bindFirestoreRef }, payload) => {
    try {
      const { key, path } = payload;
      const response = await bindFirestoreRef(key, getFirestoreRef(DB, path));
      return response
    } catch (e) {
      console.error(e);
      throw e
    }
  }),
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
