import userHasProjectClaim from './userHasProjectClaim.js'

async function run(auth_user, requires_auth){
  // console.log(auth_user, requires_auth)
  const user_has_project_claim = await userHasProjectClaim(auth_user);
  // console.log(user_has_project_claim)
  return new Promise((resolve, reject) => {
    try {
      if(user_has_project_claim && requires_auth){
        resolve(true)
      } else if(user_has_project_claim && !requires_auth){
        resolve(true)
      } else if(!user_has_project_claim && requires_auth){
        resolve(false)
      } else {
        resolve(false)
      }
    } catch(e){
      reject(e)
    }
  })
}

export default run;
