import userHasProjectClaimAndPermission from './userHasProjectClaimAndPermission.js'

async function run(requires_permission, permission_array){
  let permissions = permission_array ? permission_array : ['admin', 'owner'];
  const user_has_project_claim_and_permission = await userHasProjectClaimAndPermission(permissions);
  return new Promise((resolve, reject) => {
    try {
      if(user_has_project_claim_and_permission && requires_permission){
        resolve(true)
      } else if(user_has_project_claim_and_permission && !requires_permission){
        resolve(true)
      } else if(!user_has_project_claim_and_permission && requires_permission){
        resolve(false)
      } else {
        resolve(true)
      }
    } catch(e){
      reject(e)
    }
  })
}

export default run;
