/* modal.store.js */
const initialState = () => ({
  is_modal_active: false,
  modal: 'loading',
  media: [],
  media_path: null,
  settings_active_parent: null,
  settings_media_type: null,
  settings_active_doc: null,
  settings_picker_type: null,
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  }
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('okay');
    })
  },
  resetState: (context) => {
    return new Promise((resolve) => {
      context.commit('RESET');
      resolve('Okay');
    })
  },
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
