<template>
  <section id="app" ref="bcvContainer">
    <app-modal />
    <the-navigation v-if="user && currentUser" :user_role="user_role" />
    <router-view :key="$route.fullPath" :user_role="user_role" />
    <the-footer-wrapper v-if="user && currentUser && router_view_is_ready && project" />
  </section>
</template>

<script>
import invert from 'invert-color';
import { ColorUpdater } from "bulma-css-vars";
import { bulmaCssVariablesDefs } from "@/bulma-generated/bulma-colors";
import appMixin from '@/mixins/appMixin'
const updater = new ColorUpdater(bulmaCssVariablesDefs);

export default {
  name: 'app',
  metaInfo() {
    let analytics = this.$analytics
    let title = 'Shirly'
    let description = 'Organization directories made easy.'
    if (this.project_title) {
      title = this.project_title;
    }
    if (this.project_description) {
      description = this.project_description;
    }
    return {
      changed(metaInfo) {
        analytics.setCurrentScreen(metaInfo.title);
        analytics.logEvent("page_view");
        analytics.logEvent("screen_view", {
          app_name: "web",
          screen_name: metaInfo.title,
        });
      },
      link: [{
        rel: 'icon',
        href: this.favicon
      }],
      title: title,
      htmlAttrs: {
        lang: 'en-US'
      }
    }
  },
  components: {
    'the-navigation': () => import('@/components/TheNavigation.vue'),
    'the-footer-wrapper': () => import('@/components/TheFooterWrapper.vue'),
    'app-modal': () => import('@/components/AppModal.vue'),
  },
  mixins: [appMixin],
  data() {
    return {
      cssVars: [],
      isFullPage: true,
    };
  },
  created() {
    this.$store.dispatch('User/bindUser');
  },
  beforeDestroy() {

  },
  watch: {
    currentUser(newValue) {
      this.updateUserLastRefreshTime(newValue)
    }
  },
  mounted() {
    this.setColor('#485fc7')
    if (this.currentUser) {
      this.updateUserLastRefreshTime(this.currentUser)
    }
  },
  computed: {
    project_image() {
      return this.$store.getters['Settings/getState']('project_image');
    },
    project_title() {
      return this.project && this.hasKey(this.project, 'info') && this.project.info && this.hasKey(this.project.info, 'project_title') && this.project.info.project_title ? this.project.info.project_title : null;
    },
    project_description() {
      return this.project && this.hasKey(this.project, 'info') && this.project.info && this.hasKey(this.project.info, 'project_description') && this.project.info.project_description ? this.project.info.project_description : null;
    },
    favicon: {
      get: function () {
        return this.project_image && this.hasKey(this.project_image, 'public_id') && this.project_image.public_id ? `https://res.cloudinary.com/app-shirly/image/upload/c_fill,h_16,w_16/${this.project_image.public_id}` : null;
      },
    },
    isRouterModalActive() {
      return this.$store.getters['Admin/getIsRouterModalActive']
    },
    router_view_is_ready() {
      return this.$store.getters['Admin/getRouterViewIsReady']
    },
    stripe_product_local() {
      return this.$store.getters['Stripe/getStripeProductLocal']
    },
    isCustomDomain() {
      return this.$customDomain
    },
    user() {
      return this.$store.getters['User/getState']('user')
    },
    user_role() {
      return this.$store.getters['User/getUserRole']
    },
    currentUser() {
      return this.$store.getters['User/getCurrentUser']
    },
    project() {
      return this.$store.getters['Project/getState']('project')
    },
    project_color() {
      return this.project?.info?.project_color;
    },
    theme() {
      const themeArray = this.$store.getters['Settings/getState']('theme');
      if (themeArray) {
        return themeArray[0]
      }
    },
    container() {
      var container = this.$store.getters['Settings/getContainer']
      if (container) {
        return this.$store.getters['Settings/getContainer']
      } else {
        return null
      }
    },
    project_settings_are_loaded() {
      return this.$store.getters['Settings/getProjectSettingsAreLoaded']
    }
  },
  watch: {
    project_color: {
      handler(newValue) {
        this.setColor(newValue)
      },
      deep: true
    }
  },
  methods: {
    async updateUserLastRefreshTime(currentUser) {
      const { uid } = currentUser;
      if (uid) {
        await this.$store.dispatch('Functions/callFunction', {
          function_name: 'usersOnCallUsersSwitch',
          function_payload: {
            action: 'update_timestamp', path: `users/${uid}`, payload: {
              type: 'lastRefreshTime',
              uid: uid
            }
          }
        })
      }
    },
    removeHash(str) {
      return str.replace(/#/g, "");
    },
    replaceSlashWithColon(str) {
      return str.replace(/\//g, ":");
    },
    hasKey(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key) ? true : false;
    },
    openToast(toastObj) {
      this.$buefy.toast.open(toastObj)
    },
    forceRerender() {
      this.componentKey += 1
      // const pUrl = this.theme.find(x => x.id === 'palette').url
    },
    setColor(newColor) {
      const container = this.$refs.bcvContainer;
      if (container) {
        const updatedVars = updater.getUpdatedVars("primary", newColor);
        var newColorInvert = invert(newColor, {
          black: '#3a3a3a',
          white: '#fafafa'
        })
        updatedVars.push(updater.getUpdatedVars("primary-invert", newColorInvert)[0])
        this.cssVars = updatedVars.map(({
          name,
          value
        }) => {
          container.style.setProperty(name, value);
          return `${name}: ${value};`;
        });
      }
    }
  }
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  bottom: 50px;
  right: 50px;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>
