/* cloudinary.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  DB
} from '@/firebase/db'
// import {
//   Auth
// } from '@/firebase/auth'
import {
  Functions
} from '@/firebase/functions'
import getFirestoreRef from '@/store/modules/plugins/getFirestoreRef';


const cloudinary_wrapper = require('@/static/cloudinary_wrapper_init.js').items
const initialState = () => ({
  profile_images_tmp_orphans: [],
  activeTabItem: cloudinary_wrapper[0],
  media: ['photos', 'logos', 'groups', 'profile_images', 'stripe_products', 'shirly_profile_images'],
  photos: [],
  logos: [],
  groups: [],
  profile_images_tmp: [],
  stripe_products: [],
  shirly_profile_images: [],
  projectMediaIsLoaded: false,
  cloudinary_preset_images: [],
})

import * as format from '@/store/modules/plugins/format'
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getProfileImagesTmpOrphans: state => state.profile_images_tmp_orphans,
  getActiveTabItem: state => state.activeTabItem,
  getProjectMediaIsLoaded: state => state.projectMediaIsLoaded,
  getCloudinaryPresetImages: state => state.cloudinary_preset_images,
  getMediaByMediaType: (state) => (media_type) => {
    return state[media_type]
  },
  getMediaByMediaTypeAndMediaPublicId: (state) => (media_type, media_public_id) => {
    return state[media_type].find(media => media.public_id === media_public_id)
  },
  getGroupsMediaByGroup: (state) => (group_id) => {
    return state.groups.filter((group) => {
      return group.tags.find(x => x === group_id)
    })
  },
  getAllMedia: (state) => {
    let media = []
    state.media.map((medium) => {
      media.push(...state[medium])
    })
    return media
  },
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  setActiveTabItem: (context, payload) => {
    context.commit('SET_STATE', { key: 'activeTabItem', value: payload })
  },
  setCloudinaryPresetImages: (context, payload) => {
    context.commit('SET_STATE', { key: 'cloudinary_preset_images', value: payload })
  },
  bindMediaRefForProject: (async (context) => {
    let project_id = context.rootState.Admin.project_id;
    const promises = Object.values(context.state.media).map(async (value) => {
      const payload = {
        key: value,
        path: `projects/${project_id}/media`,
        query: {
          key: 'media_type',
          operator: '==',
          value: value
        }
      };
      await context.dispatch('bindState', payload);
    });
    await Promise.all(promises);
    context.commit('SET_STATE', { key: 'projectMediaIsLoaded', value: true });
  }),


  bindState: firestoreAction(async (context, payload) => {
    const { bindFirestoreRef } = context;
    const { key, path, orderBy, query } = payload;
    try {
      const firestoreRef = getFirestoreRef(DB, path);

      if (orderBy) {
        await bindFirestoreRef(key, firestoreRef.orderBy(orderBy.value, orderBy.direction));
      } else if (query) {
        await bindFirestoreRef(key, firestoreRef.where(query.key, query.operator, query.value));
      } else {
        await bindFirestoreRef(key, firestoreRef);
      }
    } catch (error) {
      console.error('Error binding Firestore reference:', error);
    }
  }),


  deleteImageFromCloudinary: firestoreAction((context, payload) => {
    var data = payload
    const destroyImage = Functions.httpsCallable("destroyImage")
    return new Promise((resolve, reject) => {
      return destroyImage(data)
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),

  getCloudinaryImagesFromDirectory: firestoreAction((context) => {
    const bulkImportData = context.rootState.Admin.bulkImportData
    context.dispatch('setProfileImagesTmpOrphans', context.state.profile_images_tmp)
    return format.formatBulkImportProfileImages(bulkImportData, state.profile_images_tmp_orphans).then(r => {
      return r
    })

  }),
  setProfileImagesTmpOrphans: (context, payload) => {
    context.commit('SET_PROFILE_IMAGES_TMP_ORPHANS', payload)
  },
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
