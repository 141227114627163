/* users.store.js */
import { firestoreAction } from 'vuexfire'
import { DB } from '@/firebase/db'

const initialState = () => ({
  public_registration: [],
  public_registration_fields: [],
  applicants: [],
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  }
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('okay');
    })
  },
  bindPublicRegistration: firestoreAction(async (context) => {
    const project = context.rootGetters['Project/getState']('project');
    const public_registration = project?.public_registration ? project.public_registration : false;
    context.dispatch('setState', { key: 'public_registration', value: public_registration })
  }),
  bindPublicRegistrationFields: firestoreAction(async (context, payload) => {
    const { project_id } = payload
    await context.bindFirestoreRef('public_registration_fields', DB.collection('projects').doc(project_id).collection('public_registration_fields'));
  }),
  bindPublicRegistrationApplicants: firestoreAction(async (context, payload) => {
    const { project_id } = payload
    await context.bindFirestoreRef('applicants', DB.collection('projects').doc(project_id).collection('public_registration_applicants'));
  }),
  resetState: (context) => {
    return new Promise((resolve) => {
      context.commit('RESET');
      resolve('Okay');
    })
  },
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
