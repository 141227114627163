export default [{
  path: '/login',
  name: 'Login',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '@/views/LoginView.vue'),
  props: route => {
    return {
      // parse URL id to str
      desired_route: route.params.desired_route,
    };
  },
},
{
  path: '/projects',
  name: 'Projects',
  component: () => import( /* webpackChunkName: "projects" */ '@/views/ProjectsView.vue'),
  meta: {
    requiresAuth: true,
  }
},
{
  path: '/profile',
  name: 'Profile',
  component: () => import( /* webpackChunkName: "profile" */ '@/views/ProfileView.vue'),
  meta: {
    requiresAuth: true
  },
  props: route => {
    return {
      project_id: route.params.project_id, // match the path parameter
    };
  },
  children: [{
    path: ':project_id',
    name: 'ProfileProject',
    component: () => import( /* webpackChunkName: "white_label" */ '@/views/ProfileView.vue'),
  }]
},
{
  path: '/register',
  name: 'Register',
  component: () => import( /* webpackChunkName: "register" */ '@/views/RegisterView.vue'),
  children: [{
    path: ':project_id',
    name: 'RegisterProject',
    component: () => import( /* webpackChunkName: "white_label" */ '@/views/RegisterProjectView.vue'),
    props: route => {
      return {
        project_id: route.params.project_id, // match the path parameter
      };
    }
  }]
},
{
  path: '/',
  name: 'Index',
  component: () => import( /* webpackChunkName: "white_label" */ '@/views/WhiteLabelView.vue'),
  meta: {
    requiresAuth: true,
  },
  children: [{
    path: ':project_id',
    name: 'Project',
    component: () => import( /* webpackChunkName: "white_label" */ '@/views/WhiteLabelView.vue'),
    props: route => {
      return {
        project_id: route.params.project_id, // match the path parameter
      };
    },
    children: [{
      path: 'home',
      name: 'Home',
      alias: '/',
      component: () => import( /* webpackChunkName: "home" */ '@/views/HomeView.vue'),
      meta: {
        requiresAuth: true
      },
      props: true,
    },
    {
      path: 'admin',
      name: 'Admin',
      component: () => import( /* webpackChunkName: "admin" */ '@/views/AdminView.vue'),
      meta: {
        requiresAuth: true,
        requiresPermission: true
      },
      props: true,
      children: [{
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'design',
        name: 'Admin.design',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/AdminView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: true
        },
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'account/:id',
        name: 'Admin.account.id',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/AdminView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: true
        },
        props: true
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'account/:id/:name',
        name: 'Admin.account.id.name',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/AdminView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: true
        },
        props: true
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'account/:id/:name/:sub_name',
        name: 'Admin.account.id.name.sub_name',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/AdminView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: true
        },
        props: true
      }]
    },
    {
      path: 'portal',
      name: 'Portal',
      component: () => import( /* webpackChunkName: "portal" */ '@/views/PortalView.vue'),
      meta: {
        requiresAuth: true
      },
      children: [{
        path: ':id',
        name: 'Portal.id',
        component: () => import( /* webpackChunkName: "portal" */ '@/views/PortalView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: false
        },
        props: true
      },
      {
        path: ':id/:name',
        name: 'Portal.id.name',
        component: () => import( /* webpackChunkName: "portal" */ '@/views/PortalView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: false
        },
        props: true
      },
      {
        path: ':id/:name/:sub_name',
        name: 'Portal.id.name.sub_name',
        component: () => import( /* webpackChunkName: "portal" */ '@/views/PortalView.vue'),
        meta: {
          requiresAuth: true,
          requiresPermission: false
        },
        props: true
      }]
    },
    {
      path: 'directory',
      name: 'Directory',
      component: () => import( /* webpackChunkName: "directory" */ '@/views/DirectoryView.vue'),
      meta: {
        requiresAuth: true
      },
      props: true,
    },
    {
      path: 'donate',
      name: 'Donate',
      component: () => import( /* webpackChunkName: "donate" */ '@/views/DonateView.vue'),
      redirect: { name: 'Donate.index' },
      meta: {
        requiresAuth: true
      },
      children: [{
        path: '',
        name: 'Donate.index',
        meta: {
          requiresAuth: true,
        },
        component: () => import( /* webpackChunkName: "admin" */ '@/components/PaymentIntent.vue'),
        props: true,
      },
      {
        path: 'success',
        name: 'Donate.success',
        component: () => import( /* webpackChunkName: "donate" */ '@/components/PaymentSuccess.vue'),
        meta: {
          requiresAuth: true,
        },
        props: route => ({ session_id: route.query.session_id })
      },
      {
        path: 'failed',
        name: 'Donate.failed',
        component: () => import( /* webpackChunkName: "donate" */ '@/components/PaymentFailed.vue'),
        meta: {
          requiresAuth: true,
        }
      }]
    },
    {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import( /* webpackChunkName: "not-found" */ '@/views/PageNotFoundView.vue'),
      meta: {
        requiresAuth: false
      }
    }
    ]
  }]
}]
