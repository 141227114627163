/* groups.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  DB
} from '@/firebase/db'

const initialState = () => ({
  groups: [],
  group_fields: [],
  group_field: {},
  groups_active: false
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
}
// Actions
const actions = {
  reset({
    commit
  }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindGroupUserField: firestoreAction(async (context) => {
    const project_id = context.rootGetters['Router/getState']('project_id');
    await context.bindFirestoreRef('group_fields', DB.collection('projects').doc(project_id).collection('user_fields').where("group", "==", true).where("project", "==", true));
    const group_fields = context.getters['getState']('group_fields');
    context.dispatch('setState', { key: 'group_field', value: group_fields[0] })
    const project = context.rootGetters['Project/getState']('project');
    const groups_active = project?.groups ? project.groups : false;
    context.dispatch('setState', { key: 'groups_active', value: groups_active })
  }),
  setMediaTags: ((context, payload) => {
    context.commit('SET_MEDIA_TAGS', payload)
  }),
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const keys = payload.key.split(".");
    const value = payload.value;

    let current = state;
    for (let i = 0; i < keys.length; i++) {
      // If it's the last key, set the value
      if (i === keys.length - 1) {
        current[keys[i]] = value;
      } else {
        // If the key doesn't exist in the state, create it as an empty object
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }

        // Move our current position in the state object down one level
        current = current[keys[i]];
      }
    }
    // console.log('key', payload.key, 'value', value)
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
