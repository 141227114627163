import Vue from 'vue'
import { vuexfireMutations } from 'vuexfire'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger'

// import the auto exporter
import modules from './modules'

Vue.use(Vuex)
// Making sure that we're doing
// everything correctly by enabling
// strict mode in the dev environment.
// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  actions: {
    reset({ commit }, payload = {}) {  // Default payload to an empty object
      const { omissions = [] } = payload;  // Use destructuring to get omissions or default to an empty array

      // Attempt to reset the state of all modules
      try {
        Object.keys(modules).forEach((moduleName) => {
          // Commit the RESET mutation for modules that have it
          if ('mutations' in modules[moduleName] && 'RESET' in modules[moduleName].mutations) {
            // Skip the reset for modules present in the omissions array
            if (omissions.includes(moduleName)) {
              // console.log('true')
              return;
            } else {
              commit(`${moduleName}/RESET`);
            }
          }
        });
      } catch (e) {
        // Log any errors
        console.error(e);
      }
    }
  },
  mutations: {
    ...vuexfireMutations
  }
  // strict: debug,
  // plugins: [cleanPlugin] // debug ? [createLogger(), cleanPlugin] : [cleanPlugin]
})
