/* design.store.js */
import Vue from 'vue'
import { firestoreAction } from 'vuexfire';
import getFirestoreRef from '@/store/modules/plugins/getFirestoreRef';
import { DB } from '@/firebase/db';

const initialState = () => ({
  is_setting_background: false,
  selectedSettingsId: null,
  selectedSettingsMedia: [],
  content: null,
  items: null,
  component_to_edit_parent: null,
  component_to_edit: null,
  selected: null,
  selected_array_index: null,
  key: null,
  value: null,
  splice: false
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  }
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('okay');
    })
  },
  bindState: firestoreAction(async ({ bindFirestoreRef }, payload) => {
    const { key, path, orderBy } = payload;
    if (orderBy) {
      await bindFirestoreRef(key, getFirestoreRef(DB, path).orderBy(`${orderBy.value, orderBy.direction}`));
    } else {
      await bindFirestoreRef(key, getFirestoreRef(DB, path));
    }
  }),

  resetState: (context, payload) => {
    const omissions = Object.prototype.hasOwnProperty.call(payload, 'omissions') ? payload.omissions : [];
    return new Promise((resolve) => {
      context.commit('RESET_WITH_OMISSIONS', omissions);
      resolve('Okay');
    })
  },
  updateEditedComponentArray: async function (context, payload) {
    const original = payload.original;
    const new_values = payload.new_values;
    for (let i = 0; i < new_values.length; i++) {
      context.commit('VUE_SET_STATE', { array: original, key: i, value: new_values[i] });
    }
  },
  updateEditedComponent: async function (context, p) {
    let payload = p ? p : {};
    let items = Object.prototype.hasOwnProperty.call(payload, 'items') ? payload.items : context.state.items;
    let component_to_edit_parent = Object.prototype.hasOwnProperty.call(payload, 'component_to_edit_parent') ? payload.component_to_edit_parent : context.state.component_to_edit_parent;
    let component_to_edit = Object.prototype.hasOwnProperty.call(payload, 'component_to_edit') ? payload.component_to_edit : context.state.component_to_edit;
    let selected = Object.prototype.hasOwnProperty.call(payload, 'selected') ? payload.selected : context.state.selected;
    let selected_array_index = Object.prototype.hasOwnProperty.call(payload, 'selected_array_index') ? payload.selected_array_index : context.state.selected_array_index;
    let key = Object.prototype.hasOwnProperty.call(payload, 'key') ? payload.key : context.state.key;
    let value = Object.prototype.hasOwnProperty.call(payload, 'value') ? payload.value : context.state.value;
    let splice = Object.prototype.hasOwnProperty.call(payload, 'splice') ? payload.splice : context.state.splice;
    let isSplice = splice ? true : false;
    let has_selected_array_index = (selected_array_index !== null && selected_array_index !== undefined && selected_array_index !== '' && Number.isInteger(selected_array_index)) ? true : false;
    if (component_to_edit_parent && component_to_edit && selected && !has_selected_array_index && key) {
      let item = items.find(i => i.id === component_to_edit_parent.id);
      const item_index = items.findIndex((item) => item.id === component_to_edit_parent.id);
      let child = item.children.find(j => j.id === component_to_edit.id);
      const child_index = item.children.findIndex((item) => item.id === component_to_edit.id);
      if (Object.prototype.hasOwnProperty.call(child, selected)) {
        if (isSplice) {
          context.dispatch('updateEditedComponentArray', { original: items[item_index].children[child_index][selected][key], new_values: value })
        } else {
          context.commit('VUE_SET_STATE', { array: items[item_index].children[child_index][selected], key: key, value: value });
        }
      }
    } else if (component_to_edit_parent && component_to_edit && !selected && !has_selected_array_index && key) { // selected is null (first order object, not nested with another object)
      let item = items.find(i => i.id === component_to_edit_parent.id);
      const item_index = items.findIndex((item) => item.id === component_to_edit_parent.id);
      let child = item.children.find(j => j.id === component_to_edit.id);
      const child_index = item.children.findIndex((item) => item.id === component_to_edit.id);
      if (Object.prototype.hasOwnProperty.call(child, key)) {
        if (isSplice) {
          context.dispatch('updateEditedComponentArray', { original: items[item_index].children[child_index][key], new_values: value })
        } else {
          context.commit('VUE_SET_STATE', { array: items[item_index].children[child_index], key: key, value: value });
        }
      }
    } else if (!component_to_edit_parent && component_to_edit && selected && !has_selected_array_index && key) { // selected is null (first order object, not nested with another object)
      let item = items.find(i => i.id === component_to_edit.id);
      const item_index = items.findIndex((item) => item.id === component_to_edit.id);
      if (Object.prototype.hasOwnProperty.call(item.data, selected)) {
        if (isSplice) {
          context.dispatch('updateEditedComponentArray', { original: items[item_index].data[selected][key], new_values: value })
        } else {
          context.commit('VUE_SET_STATE', { array: items[item_index].data[selected], key: key, value: value });
          context.dispatch('Settings/setState', { key: component_to_edit.id, value: items[item_index] }, { root: true });
          if (component_to_edit.id === 'theme') {
            context.dispatch('Settings/setState', { key: 'theme', value: items[item_index] }, { root: true });
            context.dispatch('Settings/handleZoom', context.rootState.Admin.device, { root: true });
          }
        }
      }
    } else if (!component_to_edit_parent && component_to_edit && !selected && !has_selected_array_index && key) { // selected is null (first order object, not nested with another object)
      let item = items.find(i => i.id === component_to_edit.id);
      const item_index = items.findIndex((item) => item.id === component_to_edit.id);
      if (Object.prototype.hasOwnProperty.call(item.data, key)) {
        if (isSplice) {
          context.dispatch('updateEditedComponentArray', { original: items[item_index].data[key], new_values: value });
        } else {
          context.commit('VUE_SET_STATE', { array: items[item_index].data, key: key, value: value });
          context.dispatch('Settings/setState', { key: component_to_edit.id, value: this.items[item_index].data }, { root: true })
        }
      }
    } else if (component_to_edit_parent && component_to_edit && selected && has_selected_array_index && key) { // selected is null (first order object, not nested with another object)
      let item = items.find(i => i.id === component_to_edit_parent.id);
      const item_index = items.findIndex((item) => item.id === component_to_edit_parent.id);
      let child = item.children.find(j => j.id === component_to_edit.id);
      const child_index = item.children.findIndex((item) => item.id === component_to_edit.id);
      if (Object.prototype.hasOwnProperty.call(child, selected)) {
        if (isSplice) {
          context.dispatch('updateEditedComponentArray', { original: items[item_index].children[child_index][selected][selected_array_index][key], new_values: value });
        } else {
          context.commit('VUE_SET_STATE', { array: items[item_index].children[child_index][selected][selected_array_index], key: key, value: value });
        }
      }
    }
  }
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  RESET_WITH_OMISSIONS: (state, omissions) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      if (!omissions.includes(key)) {
        state[key] = newState[key]
      }
    })
  },
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  VUE_SET_STATE: (state, payload) => {
    const array = payload.array;
    const key = payload.key;
    const value = payload.value;
    Vue.set(array, key, value)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
