/* events.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  DB
} from '@/firebase/db'
import {
  Functions
} from '@/firebase/functions'

import { Router } from '@/router/index'
import getFirestoreRef from '@/store/modules/plugins/getFirestoreRef';

// import the auto exporter

const initialState = () => ({
  project_events: [],
  project_events_with_current_user_roles: [],
  project_events_without_current_user_roles: [],
  active_project_event_attendees: []
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getProjectEvents: state => state.project_events,
  getProjectEventsWithCurrentUserRoles: state => state.project_events_with_current_user_roles,
  getProjectEventsWithoutCurrentUserRoles: state => state.project_events_without_current_user_roles,
  getEventById: (state) => (event_id) => {
    return state.project_events.find(event => event.id === event_id)
  },
}
// Actions
const actions = {
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindState: firestoreAction(async ({ bindFirestoreRef }, payload) => {
    try {
      const { key, path } = payload;
      const response = await bindFirestoreRef(key, getFirestoreRef(DB, path));
      return response
    } catch (e) {
      console.error(e);
      throw e
    }
  }),
  bindEventsRefForProject: firestoreAction(async (context) => {
    return new Promise((resolve) => {
      let project_id = context.rootState.Admin.project_id
      context.bindFirestoreRef('project_events', DB.collection('projects').doc(project_id).collection('events'))
      resolve(context.state.project_events)
    })
  }),
  bindEventsWithCurrentUserRolesForProject: firestoreAction(async (context) => {
    if (context.state.project_events) {
      const project_events = [...context.state.project_events]
      const array = []
      for (let i = 0; i < project_events.length; i++) {
        array.push(context.dispatch('getUserRoleForEvent', { event_id: project_events[i].id }))
      }
      await Promise.all(array).then(all_events => {
        const has_roles_true = all_events.filter((item) => Object.prototype.hasOwnProperty.call(item, "role"))
        const has_roles_false = all_events.filter((item) => !Object.prototype.hasOwnProperty.call(item, "role"))
        context.commit('SET_PROJECT_EVENTS_WITH_CURRENT_USER_ROLES', has_roles_true)
        context.commit('SET_PROJECT_EVENTS_WITHOUT_CURRENT_USER_ROLES', has_roles_false)
      })
    }
  }),
  projectEventAction: firestoreAction(async (context, payload) => {
    const action = payload.action
    const event_data = payload.event_data
    const event_attendees = payload.event_attendees
    let project_id = context.rootState.Admin.project_id
    let current_user_token = await context.dispatch('User/getCurrentUserToken', {}, { root: true })
    var data = {
      project_id: project_id,
      event_attendees: event_attendees,
      action: action, // add, update, delete
      event_data: event_data, //update and delete objects must contain document id
      current_user_token: current_user_token
    }
    const projectEventAction = Functions.httpsCallable("projectEventAction")
    return new Promise((resolve, reject) => {
      return projectEventAction(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  getUserRoleForEvent: async function (context, payload) {
    let project_id = context.rootState.Admin.project_id
    const event_id = payload.event_id
    const user_id = context.rootGetters['User/getUser'].uid
    // console.log(project_id, event_id, user_id)
    // return new Promise((resolve, reject) => {
    var docRef = DB.collection('projects').doc(project_id).collection('events').doc(event_id).collection('attendees').doc(user_id)
    return docRef.get().then(doc => {
      if (doc.exists) {
        let event = context.getters['getEventById'](event_id)
        return { ...event, role: doc.data().role, id: event_id }
      } else {
        // doc.data() will be undefined in this case
        let event = context.getters['getEventById'](event_id)
        return { ...event, id: event_id }
      }
    })
    // })
  },
  getUsersByEvent: async function (context, payload) {
    let project_id = context.rootState.Admin.project_id
    const event_id = payload.event_id
    // console.log(project_id, event_id, user_id)
    // return new Promise((resolve, reject) => {
    var collectionRef = DB.collection('projects').doc(project_id).collection('events').doc(event_id).collection('attendees')
    return collectionRef.get().then((querySnapshot) => {
      let uids = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        uids.push(doc.id)
      });
      return uids
    });
  },
  routeToEditEvent: async function (context, payload) {
    var routerParams = {
      name: 'Admin.account.id.name.sub_name',
      params: {
        id: 'events',
        name: 'edit_existing_event',
        sub_name: 'event_' + payload.id
      }
    }
    Router.push({ name: routerParams.name, params: routerParams.params })
  },
  createEditPayload: function (context, payload) {
    console.log(payload)
    var edit_payload = {};
    const prj_event = payload;
    edit_payload.id = prj_event.id;
    edit_payload.form = [];
    edit_payload.form[0] = prj_event.title;
    edit_payload.form[3] = prj_event.description;
    edit_payload.address_and_lat_lng = [];
    edit_payload.address_and_lat_lng[0] = prj_event.location;
    edit_payload.address_and_lat_lng[1] = prj_event.lat;
    edit_payload.address_and_lat_lng[2] = prj_event.lng;
    edit_payload.date_and_time = prj_event.date_and_time.map((e, i) => {
      if (i < 4) {
        return new Date(e)
      } else {
        return e
      }
    });
    edit_payload.attendees = prj_event.attendees ? prj_event.attendees : [];
    edit_payload.donation = prj_event.donation ? prj_event.donation : null;
    if (Object.prototype.hasOwnProperty.call(prj_event, 'calendar_event_id')) {
      edit_payload.calendar_event_id = prj_event.calendar_event_id
    }
    return (edit_payload)
  },
  customizeUsers: async function (context, array_of_user_ids) {
    try {
      // Filter out null values from the array of user IDs
      let filteredArray = array_of_user_ids.filter(element => element && typeof element === 'string' && element.trim() !== '');
      // Fetch users using the filtered array of user IDs
      let fetchUsersResponse = await context.dispatch('Functions/callFunction', {
        function_name: 'usersOnCallUsersSwitch',
        function_payload: {
          action: 'fetch_users',
          payload: {
            uids: filteredArray,
            fields: []
          }
        }
      }, { root: true });

      try {
        if (!fetchUsersResponse) throw new Error('Could not fetch users.');
        const { data } = fetchUsersResponse;
        // Check if 'data' is an array (no errors) or an object with 'users' and 'errors' keys
        let users, errors;
        if (Array.isArray(data)) {
          // If 'data' is an array, there are no errors, and all fetched entities are users
          users = data;
          errors = []; // No errors
        } else if (data && typeof data === 'object' && data.users) {
          // If 'data' is an object with a 'users' key, extract 'users' and 'errors'
          users = data.users;
          errors = data.errors || []; // Ensure 'errors' is an array, even if it's undefined
        } else {
          throw new Error('Unexpected response structure from fetchUsersResponse.');
        }

        // Process the 'users' array to transform the user data
        const result = users.map(user => {
          if (user) {
            let obj = {
              first_name: user.first_name || '',
              last_name: user.last_name || '',
              full_name: (user.first_name && user.last_name) ? `${user.first_name} ${user.last_name}` : '',
              uid: user.uid || null,
              profile_image: user.profile_image || null,
            };

            // Optionally add 'nickname' if it exists
            if (user.nickname) {
              obj.nickname = user.nickname;
            }

            return obj;
          }
        }).filter(user => user); // Filter out any undefined elements

        // Decide on the return structure based on the presence of errors
        if (errors.length > 0) {
          return { users: result, errors };
        } else {
          return result; // Return just the array of users if there are no errors
        }
      } catch (e) {
        console.error(e);
      }


    } catch (error) {
      console.error("Error before filtering:", error);
    }
  },

  getAttendance: async function (context, payload) {
    const event_id = payload.event_id;
    const attendee_id = payload.attendee_id;
    const project_id = payload.project_id;
    return context.dispatch('Functions/callFunction', {
      function_name: 'callProjectEventAttendeesAction', function_payload: {
        action: 'get_event_attendee',
        project_id: project_id,
        get_data: {
          id: event_id,
          attendee_id: attendee_id
        }
      }
    }, { root: true });
  }
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  SET_PROJECT_EVENTS_WITH_CURRENT_USER_ROLES: (state, payload) => {
    state.project_events_with_current_user_roles = payload
  },
  SET_PROJECT_EVENTS_WITHOUT_CURRENT_USER_ROLES: (state, payload) => {
    state.project_events_without_current_user_roles = payload
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
