exports.items = [ {
    id: 'photos',
    label: 'Photos',
    description: 'Photos are used for Home page components.',
    displayed: true,
    preset: 'photos_preset',
    media_type: 'photos'
},
{
    id: 'logos',
    label: 'Logos',
    description: 'Logos are used for Navigation and other project placeholders.',
    displayed: true,
    preset: 'logos_preset',
    media_type: 'logos'
},
{
    id: 'groups',
    label: 'Groups',
    description: 'Group photos are uploaded by users and appear in the Directory.',
    displayed: true,
    preset: 'groups_preset',
    media_type: 'groups'
},
{
    id: 'profile_photos',
    label: 'Profile',
    description: 'Temporary profile images are (optionally) added during a Bulk Import.',
    displayed: true,
    preset: 'profile_images_tmp_preset',
    media_type: 'profile_images_tmp'
},
{
    id: 'stripe',
    label: 'Stripe',
    description: 'Product images are used for Stripe donations.',
    displayed: true,
    preset: 'stripe_products_preset',
    media_type: 'stripe_products'
},
{
    id: 'profile_image',
    label: 'Profile_Image',
    description: 'Profile images are created by an individual user.',
    displayed: false,
    preset: 'shirly_profile_images_preset',
    media_type: 'profile_images'
},
];
