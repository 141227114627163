/* functions.store.js */
import {
  Functions
} from '@/firebase/functions'


const initialState = () => ({

})
// State object
const state = initialState()
// Getter functions
const getters = {

}
// Actions
const actions = {
  callFunction: (context, payload) => {
    const function_name = payload.function_name;
    const function_payload = payload.function_payload;
    const firebase_function = Functions.httpsCallable(function_name);
    return new Promise((resolve, reject) => {
      firebase_function(function_payload)
        .then((response) => {
          resolve(response.data);
        }).catch((e) => {
          reject(e);
        })
    })
  }
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
