import store from '@/store'
import { Auth } from '@/firebase/auth'

async function run() {
  const result = await Auth.currentUser.getIdTokenResult();
  const project_id = store.getters['Router/getState']('project_id');
  return new Promise((resolve) => {
    if (result && project_id) {
      let roles = result.claims;
      var roles_keys = Object.keys(roles);
      if (roles_keys.includes(project_id) || roles_keys.includes('shirly') && roles['shirly'] === 'admin') {
        resolve(true)
      } else {
        resolve(false)
      }
    } else {
      resolve(false)
    }
  })
}

export default run;
