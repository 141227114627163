module.exports = {
    formatColumns: function(keysArray) {
        const result = keysArray.map((x) => {
            return {
                field: x,
                label: x,
            };
        });
        return result;
    },
    formatData: function(array) {
        let keys = array.shift();
        let objects = array.map((values) => {
            return keys.reduce((o, k, i) => {
                o[k] = values[i];
                return o;
            }, {});
        });
        return objects;
    },
    formatBulkImportProfileImages: function(bulkImportData, orphanImages) {
        return new Promise((resolve) => {
            const result = bulkImportData.map((item) => {
                let r = orphanImages.filter((x, index) => {
                    let original_filename = x.original_filename;
                    if (original_filename === item.id) {
                        orphanImages.splice(index, 1);
                        return x;
                    }
                });
                if (r.length > 0) {
                    try {
                        item.profile_image = {
                            original_filename: r[0].original_filename,
                            public_id: r[0].public_id,
                            url: r[0].url
                        };
                    } catch (error) {
                        console.error(error);
                    }
                }
                return item;
            });
            resolve(result);
        });
    }
};
