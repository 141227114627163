/* user.store.js */
import Vue from 'vue'
import { firestoreAction } from 'vuexfire';
import { DB } from '@/firebase/db';
import { Auth } from '@/firebase/auth';
import { Functions } from '@/firebase/functions';
import { Magic } from 'magic-sdk';
import { ToastProgrammatic as Toast } from 'buefy';
import { Router, later } from '@/router/index';

// Initialize Magic SDK
const m = new Magic(process.env.VUE_APP_MAGIC_PUBLISHABLE_API_KEY);

// Initial State
const initialState = () => ({
  currentUser: null,
  user: {},
  projects: [],
  user_channels: [],
  bulk_import_sessions: [],
  user_role: null,
  user_group: null,
  is_admin: false,
  user_bound: false,
})

// State object
const state = initialState()

// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getCurrentUser: state => state.currentUser,
  getUser: state => state.user,
  getUserRole: state => state.user_role,
}
// Actions
const actions = {
  reset({
    commit
  }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindUser: async (context) => {
    let user;
    if (!context.state.currentUser) {
      context.dispatch('setCurrentUser');
    }
    user = context.getters['getState']('currentUser');
    if (user) {
      await context.dispatch('bindUserRef');
      await context.dispatch('bindProjectsRef');
      await context.dispatch('populateUserOrganizations');
      context.dispatch('setState', { key: 'user_bound', value: true })
    }
  },

  async populateUserOrganizations({ state, dispatch }) {
    const currentUser = state.currentUser;
    const organizationsCollection = DB.collection(`users/${currentUser.uid}/organizations`);
    const organizationsSnapshot = await organizationsCollection.get();
    dispatch('setState', { key: 'user.organizations', value: organizationsSnapshot.docs.map(doc => doc.data()) })
  },

  setCurrentUser: context => {
    context.commit('SET_CURRENT_USER')
  },
  setUserRole: (context, payload) => {
    context.commit('SET_USER_ROLE', payload)
  },
  setUserGroup: (context, payload) => {
    context.commit('SET_USER_GROUP', payload)
  },
  getProjectRole: async (context) => {
    const currentUser = context.state.currentUser;
    const user = await DB.collection('users').doc(currentUser.uid).get()
    const roles = user.data().roles
    let project_id = context.rootState.Admin.project_id
    const role = roles[project_id];
    return role
  },

  bindUserRef: firestoreAction(async (context) => {
    const currentUser = context.state.currentUser;
    if (currentUser) {
      context.bindFirestoreRef('user', DB.collection('users').doc(currentUser.uid));
    }
  }),
  bindUserRole: (async (context) => {
    const currentUser = context.state.currentUser;
    let user_data = await currentUser.getIdTokenResult()
    let project_id = context.rootState.Admin.project_id;
    let roles = user_data.claims;
    if (roles[project_id] === 'admin' || roles.shirly === 'admin') {
      context.commit('SET_STATE', {
        key: 'is_admin',
        value: true
      });
    }
    context.commit('SET_USER_ROLE', roles[project_id]);
  }),
  bindUserGroup: (async (context) => {
    const project_group = context.rootGetters['Groups/getGroupField'];
    if (project_group) {
      const project_group_id = project_group.id
      let user = state.user
      let group = user[project_group_id]
      context.commit('SET_USER_GROUP', group)
    }
  }),
  bindUserChannelsRef: firestoreAction(context => {
    let project_id = context.rootState.Admin.project_id
    const currentUser = context.state.currentUser;
    context.bindFirestoreRef('user_channels', DB.collection('users').doc(currentUser.uid).collection('channels').doc('projects').collection(project_id))
  }),
  bindBulkImportSessions: firestoreAction(async (context) => {
    const currentUser = context.state.currentUser;
    return await new Promise((resolve, reject) => {
      context.bindFirestoreRef('bulk_import_sessions', DB.collection('users').doc(currentUser.uid).collection('bulk_import_sessions').orderBy("createdAt", "asc"))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err)
        })
    })
  }),

  bindProjectsRef: firestoreAction(async (context) => {
    const currentUser = context.state.currentUser;
    const user = await DB.collection('users').doc(currentUser.uid).get();
    const roles = user.data().roles;
    const roles_keys = Object.keys(roles);

    // Use Promise.all to wait for all promises to resolve
    const projects = await Promise.all(roles_keys.map(role =>
      context.dispatch('Functions/callFunction', {
        function_name: 'firekitOnCallFirestoreSwitch',
        function_payload: { action: 'get', path: `projects/${role}` }
      }, { root: true }).then(result => result?.data)
    ));

    // Assuming that projects are an array, iterate through them directly
    var user_projects = [];
    projects.forEach((doc, index) => {
      if (doc) { // Check if doc exists
        var found = roles_keys[index];
        user_projects.push({
          id: doc.id,
          data: doc,
          roles: roles[found]
        });
      }
    });

    let sorted_projects = user_projects.sort((a, b) => (a.data.createdAt > b.data.createdAt) ? 1 : -1);
    context.commit('SET_STATE', {
      key: 'projects',
      value: sorted_projects
    });
  }),
  getCurrentUserToken: firestoreAction(() => { // MOVE THIS TO ADMIN
    const getCurrentUserTokenFunction = () => {
      return Auth.currentUser.getIdToken(true)
        .then(function (token) {
          // You got the user token
          return token
        })
        .catch(function (err) {
          return (err);
        });
    }
    return new Promise((resolve, reject) => {
      getCurrentUserTokenFunction()
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  doesUserEmailExist: (context, payload) => {
    const doesUserEmailExist = Functions.httpsCallable("doesUserEmailExist")
    return new Promise((resolve, reject) => {
      return doesUserEmailExist(payload)
        .then((response) => {
          resolve(response);
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  },

  setCookie: (context, payload) => {
    const { name, value, domain } = payload;
    const expires = new Date();
    expires.setTime(expires.getTime() + (24 * 60 * 60 * 7 * 1000)); // 7 days
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};domain=${domain};path=/;`;
  },

  deleteCookie: (context, payload) => {
    const { name, domain } = payload;
    const expiredDate = new Date(0).toUTCString(); // Setting the date to epoch time (January 1, 1970), effectively expiring it
    document.cookie = `${name}=; expires=${expiredDate}; domain=${domain};path=/;`;
  },

  login: async (context, payload) => {

    function setModalLoadingState(isLoading) {
      context.dispatch('Modal/setState', { key: 'is_modal_active', value: isLoading }, { root: true });
      if (isLoading) {
        context.dispatch('Modal/setState', { key: 'modal', value: 'loading' }, { root: true });
      }
    }

    function handleFailedLogin(result) {
      setModalLoadingState(false);
      Toast.open({
        duration: 10000,
        message: `${result.message}`,
        position: 'is-top',
        type: 'is-warning'
      });
    }

    try {
      const { email, phone, supplied_did_token } = payload || {};
      const origin = window.location.origin;
      let didToken = supplied_did_token;

      if (!didToken) {
        if (email) {
          didToken = await m.auth.loginWithEmailOTP({
            email,
          });
        } else if (phone) {
          didToken = await m.auth.loginWithSMS({
            phoneNumber: phone,
          });
        }
      }
      const functionPayload = {
        action: 'login',
        payload: {
          domain: origin,
          magicToken: didToken,
          redirect: origin
        }
      };
      context.dispatch('Modal/setState', { key: 'modal', value: 'signin' }, { root: true })
      context.dispatch('Modal/setState', { key: 'is_modal_active', value: true }, { root: true })

      const result = await context.dispatch('Functions/callFunction', {
        function_name: 'authenticateOnCallAuthenticate',
        function_payload: functionPayload
      }, { root: true });
      const decision = result?.type || 'Failed';
      if (decision === 'Failed') {
        if (result?.message) {
          const { message } = result;
          const isInvalidToken = message.includes("Magic token is invalid") || message.includes("The DID token is malformed");
          if (!isInvalidToken) {
            handleFailedLogin(result);
          }
        }
      } else {
        try {
          await Auth.signInWithCustomToken(result.data.token);
          if (result.data && result.data.uid) {
            const magic_token = result.data.token;
            context.dispatch('deleteCookie', { name: 'magic', domain: '.shir.ly' })
            let name = 'magic';
            let value = magic_token;
            let domain = '.shir.ly'
            context.dispatch('setCookie', { name, value, domain })

            await Auth.signInWithCustomToken(result.data.token);
            context.dispatch('deleteCookie', { name: 'session', domain: '.shir.ly' })
            const client_token = await Auth.currentUser.getIdToken(false);
            const token = client_token;
            let functionPayload = {
              action: 'create_cookie',
              payload: { token }
            }
            const r = await context.dispatch('Functions/callFunction', {
              function_name: 'authenticateOnCallAuthenticate',
              function_payload: functionPayload
            }, { root: true });
            name = 'session';
            value = r;
            domain = '.shir.ly'
            context.dispatch('setCookie', { name, value, domain })
            const user = await DB.collection('users').doc(result.data.uid).get();
            if (user.exists) {
              context.dispatch('bindUser');
              await context.dispatch('Functions/callFunction', {
                function_name: 'usersOnCallUsersSwitch',
                function_payload: {
                  action: 'update_timestamp', path: `users/${uid}`, payload: {
                    type: 'lastLogInTime',
                    uid: uid
                  }
                }
              }, { root: true })
              setModalLoadingState(false);
              await Router.push({
                name: 'Home'
              }).catch(err => console.error("Navigation error:", err));
            }
          } else {
            console.error("UID is missing");
          }
        } catch (error) {
          console.error("Error accessing user document:", error);
          // Handle the error appropriately
        }
      }
      context.dispatch('Modal/setState', { key: 'modal', value: 'loading' }, { root: true })
      context.dispatch('Modal/setState', { key: 'is_modal_active', value: false }, { root: true })
      return decision !== 'Failed' ? 'Success' : 'Failed';

    } catch (e) {
      console.error(e); // For better error debugging
      return 'Failed';
    }
  },

  logout: async (context) => {
    const router_view_wait = 500;
    context.dispatch('Admin/setIsAppLoading', true, {
      root: true
    })
    await m.user.logout().then(() => {
      Auth
        .signOut()
        .then(async () => {
          context.commit('CLEAR_USER_DATA');
          context.dispatch('reset', { omissions: ['Settings'] }, {
            root: true
          });
          const name = 'session';
          const domain = '.shir.ly'
          context.dispatch('deleteCookie', { name, domain })
          await later(router_view_wait);
          let currentPath = Router.currentRoute.path;
          if (currentPath !== '/login') {
            await Router.push({
              name: 'Login'
            });
            await later(300);
            context.dispatch('Admin/setIsAppLoading', false, {
              root: true
            });
          } else {
            context.dispatch('Admin/setIsAppLoading', false, {
              root: true
            });
            await Router.go(0);
          }
        })
        .catch(error => {
          context.commit("setError", error.message);
        });
    });
  },
}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const keys = payload.key.split(".");
    const value = payload.value;

    let current = state;
    for (let i = 0; i < keys.length; i++) {
      // If it's the last key, set the value using Vue.set for reactivity
      if (i === keys.length - 1) {
        Vue.set(current, keys[i], value);
      } else {
        // If the key doesn't exist in the state, create it as an empty object using Vue.set
        if (!current[keys[i]]) {
          Vue.set(current, keys[i], {});
        }

        // Move our current position in the state object down one level
        current = current[keys[i]];
      }
    }
    // console.log('key', payload.key, 'value', value)
  },
  SET_CURRENT_USER: state => {
    state.currentUser = Auth.currentUser
  },
  SET_USER_ROLE: (state, payload) => {
    state.user_role = payload
  },
  SET_USER_GROUP: (state, payload) => {
    state.user_group = payload
  },
  CLEAR_USER_DATA: state => {
    state.currentUser = null
    state.user = {}
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
