/* router.store.js */
const initialState = () => ({
  router_guard_ready: true,
  loading_status: 'Initialing',
  current_user: null,
  is_app_bound: false,
  is_private_label: null,
  domain: null,
  path: null,
  project_id: null,
  project_is_ready: false,
  settings_are_ready: false,
  app_is_ready: false,
  router_can_proceed: true
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  }
}
// Actions
const actions = {
  setState: (context, payload) => {
    if(payload.key === 'loading_status'){
      // console.log(payload.value)
    }
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('okay');
    })
  },
}
// Mutations
const mutations = {
  RESET: (state) => {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
