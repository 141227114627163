/* project.store.js */
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import getFirestoreRef from '@/store/modules/plugins/getFirestoreRef';
import { DB } from '@/firebase/db';

const initialState = () => ({
  project: {
    info: {
      project_color: '#485fc7'
    }
  },
  import_sheets: [],
  import_sheet: {},
  export_sheets: [],
  export_sheet: {},
  project_id: null,
  bulk_import_sessions: [],
  project_channels: [],
  project_events: [],
  project_mail: [],
  project_secrets: null,
  queue_docs: [],
  active_bulk_import_session: null,
  active_bulk_import_session_users: null,
  active_bulk_import_session_profile_images: null,
  active_bulk_import_session_user_pairs: null,
  active_bulk_import_session_users_not_in_pair: null,
  active_bulk_import_session_images_not_in_pair: null,
  bulk_import_sessions_navigation_button_disabled: {
    previous: false,
    next: true
  }
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getProjectParam: (state) => (key) => {
    return state.project.find(item => item.key === key)
  },
}
// Actions
const actions = {
  setProject: (context, payload = null) => {
    return new Promise(async (resolve, reject) => {
      const project_id = context.rootGetters['Router/getState']('project_id');
      const functions = {
        project: () => context.dispatch('Functions/callFunction', { function_name: 'firekitOnCallFirestoreSwitch', function_payload: { action: 'get', path: `projects/${project_id}`, project_id: project_id } }, { root: true }),
        bulk_import_sessions: () => context.dispatch('Functions/callFunction', { function_name: 'firekitOnCallFirestoreSwitch', function_payload: { action: 'list', path: `projects/${project_id}/bulk_import_sessions`, project_id: project_id } }, { root: true }),
        project_channels: () => context.dispatch('Functions/callFunction', { function_name: 'firekitOnCallFirestoreSwitch', function_payload: { action: 'list', path: `projects/${project_id}/channels`, project_id: project_id } }, { root: true }),
        project_events: () => context.dispatch('Functions/callFunction', { function_name: 'firekitOnCallFirestoreSwitch', function_payload: { action: 'list', path: `projects/${project_id}/events`, project_id: project_id } }, { root: true }),
        project_mail: () => context.dispatch('Functions/callFunction', { function_name: 'firekitOnCallFirestoreSwitch', function_payload: { action: 'list', path: `projects/${project_id}/mail`, project_id: project_id } }, { root: true }),
        project_secrets: () => context.dispatch('Functions/callFunction', { function_name: 'firekitOnCallFirestoreSwitch', function_payload: { action: 'list', path: `projects/${project_id}/secrets`, project_id: project_id } }, { root: true }),
      };

      const project_data = payload || Object.keys(functions);  // use payload keys if provided, or all keys
      let resultObj = {};  // To hold all the results

      for (const key of project_data) {
        if (functions[key] && typeof functions[key] === 'function') {  // only call if it's a valid function
          try {
            const result = await functions[key]();  // get data using function mapped to key
            context.commit('SET_STATE', {
              key: key,
              value: result.data
            });
            resultObj[key] = result.data;
          } catch (error) {
            console.warn(`Error calling function for key: ${key}`);
            reject({ status: "failed", error: error });
            return;
          }
        } else {
          console.warn(`No function found for key: ${key}`);
        }
      }

      resolve({ status: "success", data: resultObj });
    });
  },
  bindSheets: firestoreAction(async (context) => {
    const project_id = context.rootGetters['Router/getState']('project_id');
    await context.bindFirestoreRef('import_sheets', DB.collection('projects').doc(project_id).collection('sheets').where("sheetName", "==", 'import'));
    const import_sheets = context.getters['getState']('import_sheets');
    context.dispatch('setState', { key: 'import_sheet', value: import_sheets[0] })
    await context.bindFirestoreRef('export_sheets', DB.collection('projects').doc(project_id).collection('sheets').where("sheetName", "==", 'export'));
    const export_sheets = context.getters['getState']('export_sheets');
    context.dispatch('setState', { key: 'export_sheet', value: export_sheets[0] })
  }),
  bindQueueDocs: firestoreAction(async (context, payload) => {
    const { id } = payload
    const project_id = context.rootGetters['Router/getState']('project_id');
    await context.bindFirestoreRef('queue_docs', DB.collection('projects').doc(project_id).collection('queue').where("id", "==", id));
  }),
  reset({ commit }) {
    commit('RESET')
  },
  bindState: firestoreAction(async ({ bindFirestoreRef }, payload) => {
    try {
      const { key, path } = payload;
      const response = await bindFirestoreRef(key, getFirestoreRef(DB, path));
      return response
    } catch (e) {
      console.error(e);
      throw e
    }
  }),
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
}
// Mutations
const mutations = {
  // include vuexfire mutations
  ...vuexfireMutations,
  SET_STATE: (state, payload) => {
    const keys = payload.key.split(".");
    const value = payload.value;

    let current = state;
    for (let i = 0; i < keys.length; i++) {
      // If it's the last key, set the value
      if (i === keys.length - 1) {
        current[keys[i]] = value;
      } else {
        // If the key doesn't exist in the state, create it as an empty object
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }

        // Move our current position in the state object down one level
        current = current[keys[i]];
      }
    }
    // console.log('key', payload.key, 'value', value)
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
