import store from '@/store'
import { Auth } from '@/firebase/auth'

async function run(permission_array) {
  const result = await Auth.currentUser.getIdTokenResult();
  const project_id = store.getters['Router/getState']('project_id');
  return new Promise((resolve) => {
    if (result && project_id) {
      let roles = result.claims;
      var roles_keys = Object.keys(roles);
      let has_role = roles_keys.includes(project_id) ? true : false;
      let is_shirly_admin = roles_keys.includes('shirly') && roles.shirly === 'admin' ? true : false;
      if (is_shirly_admin) {
        resolve(true)
      } else if (has_role) {
        const project_role = roles[project_id];
        const decision = permission_array.includes(project_role);
        if (decision) {
          resolve(true)
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    } else {
      resolve(false)
    }
  })
}

export default run;
