export const menu = [{
  parent: null,
  children: null,
  hidden: true,
  name: 'Admin',
  icon: null,
  id: 'admin',
  subtitle: "This is where the sausage is made."
},
{
  parent: null,
  children: null,
  name: 'Design',
  icon: 'brush',
  id: 'design',
  subtitle: 'Use the aside menu to customize your home page.'
},
{
  parent: null,
  asideParent: true,
  children: ['Users', 'Groups', 'Donate', 'Photos', 'Events'],
  name: 'Account',
  icon: 'cog',
  id: 'account',
  subtitle: 'Manage your project.',
},
{
  parent: 'Account',
  asideParent: false,
  children: ['Add New User', 'Edit Existing User'],
  name: 'Users',
  icon: 'account',
  id: 'users',
  subtitle: 'Add, remove, or modify user profiles.',
},
{
  parent: 'Users',
  children: null,
  hidden: true,
  name: 'Add New User',
  icon: 'account-plus',
  id: 'add_new_user',
  subtitle: 'Please fill out all of the required fields.',
},
{
  parent: 'Users',
  children: null,
  hidden: true,
  name: 'Edit Existing User',
  icon: 'account-edit',
  id: 'edit_existing_user',
  subtitle: 'Please amend all of the required fields.',
},
{
  parent: 'Users',
  children: null,
  hidden: true,
  name: 'Manage User Form',
  icon: 'account-cog',
  id: 'manage_user_form',
  subtitle: 'Select applicable and required fields for users.',
},
{
  parent: 'Users',
  children: null,
  hidden: true,
  name: 'Manage Registration',
  icon: 'account-cog',
  id: 'manage_registration',
  subtitle: 'Configure project public registration.',
},
{
  parent: 'Manage User Form',
  children: null,
  hidden: true,
  name: 'Manage Organizations',
  icon: 'account-cog',
  id: 'manage_organizations',
  sub_name: 'manage_organizations',
  subtitle: 'Control the organizations and positions provided to users to complete a profile.',
},
{
  parent: 'Users',
  children: null,
  hidden: true,
  name: 'Bulk Import',
  icon: 'database-import-outline',
  id: 'bulk_import',
  subtitle: 'Follow the steps below to import one or more users with Google Sheets.',
},
{
  parent: 'Bulk Import',
  children: null,
  hidden: true,
  name: 'Retry Bulk Import',
  icon: 'database-import-outline',
  id: 'retry_bulk_import',
  sub_name: 'retry_bulk_import',
  subtitle: 'Retry bulk import on selected users.',
},
{
  parent: 'Bulk Import',
  children: null,
  hidden: true,
  name: 'Edit Existing Tmp User',
  icon: 'account-edit',
  id: 'edit_existing_tmp_user',
  subtitle: 'Edit profile for tmp users and retry import.',
},
{
  parent: 'Account',
  children: null,
  name: 'Groups',
  icon: 'group',
  id: 'groups',
  subtitle: 'Manage this feature for your project.',
},
{
  parent: 'Account',
  children: ['Add New Event', 'Edit Existing Event', 'View Existing Event'],
  name: 'Events',
  icon: 'calendar-clock',
  id: 'events',
  subtitle: 'Manage events for your project.',
},
{
  parent: 'Events',
  children: null,
  hidden: true,
  name: 'Add New Event',
  icon: 'calendar-plus',
  id: 'add_new_event',
  subtitle: 'Please fill out all of the required fields.',
},
{
  parent: 'Events',
  children: null,
  hidden: true,
  name: 'Edit Existing Event',
  icon: 'calendar-edit',
  id: 'edit_existing_event',
  subtitle: 'Please amend all of the required fields.',
},
{
  parent: 'Events',
  children: null,
  hidden: true,
  name: 'View Existing Event',
  icon: 'calendar',
  id: 'view_existing_event',
  subtitle: 'Find the details of your event.',
},
{
  parent: 'Account',
  children: ['Add New Stripe Product', 'Add New Stripe Payment Link', 'Edit Existing Stripe Product', 'Edit Existing Stripe Payment Link'],
  name: 'Donate',
  icon: 'cash-multiple',
  id: 'donate',
  subtitle: 'Manage donations on your project.',
},
{
  parent: 'Donate',
  children: null,
  hidden: true,
  name: 'Add New Stripe Product',
  icon: 'cash-plus',
  id: 'add_new_stripe_product',
  subtitle: 'Please fill out all of the required fields.',
},
{
  parent: 'Donate',
  children: null,
  hidden: true,
  name: 'Add New Stripe Payment Link',
  icon: 'cash-plus',
  id: 'add_new_stripe_payment_link',
  subtitle: 'Please fill out all of the required fields.',
},
{
  parent: 'Donate',
  children: null,
  hidden: true,
  name: 'Edit Existing Stripe Product',
  icon: 'cash-sync',
  id: 'edit_existing_stripe_product',
  subtitle: 'Please amend all of the required fields.',
},
{
  parent: 'Donate',
  children: null,
  hidden: true,
  name: 'Edit Existing Stripe Payment Link',
  icon: 'cash-sync',
  id: 'edit_existing_stripe_payment_link',
  subtitle: 'Please amend all of the required fields.',
},
{
  parent: 'Account',
  children: null,
  name: 'Photos',
  icon: 'camera',
  id: 'photos',
  subtitle: 'Manage Cloudinary media on your project.',
},
{
  parent: null,
  hidden: true,
  children: null,
  name: 'Error',
  icon: 'error',
  id: 'error',
  subtitle: 'Cannot locate the provided route.',
}
]
