/* stripe.store.js */
import { firestoreAction } from 'vuexfire'
import { DB } from '@/firebase/db'
import { Functions } from '@/firebase/functions'
import { loadStripe } from '@stripe/stripe-js';
import clean from './plugins/clean'

const initialState = () => ({
  stripe_products: [],
  stripe_product_local: {},
  stripe_checkout_session: null,
  donations: false,
  field_data_type: 'stripe_product'
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
  getStatePromise: (state) => (key) => {
    return new Promise((resolve) => {
      resolve(state[key])
    })
  },
  getStripeProducts: state => state.stripe_products,
  getStripeProductLocal: state => state.stripe_product_local,
  getStripeProductById: (state) => (id) => {
    return state.stripe_products.find(product => product.id === id)
  },
}
// Actions
const actions = {
  reset({
    commit
  }) {
    commit('RESET')
  },
  setState: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('SET_STATE', payload);
      resolve('Okay');
    })
  },
  bindDonationsField: firestoreAction(async (context) => {
    const project = context.rootGetters['Project/getState']('project');
    const donations = project?.donations ? project.donations : false;
    context.dispatch('setState', { key: 'donations', value: donations })
  }),
  bindStripeProductsForProject: firestoreAction(context => {
    let project_id = context.rootState.Admin.project_id
    // const stripeRef = DB.collection('projects').doc(project_id).collection('stripe')
    context.bindFirestoreRef('stripe_products', DB.collection('projects').doc(project_id).collection('stripe'))
  }),
  bindStripeCheckoutSession: firestoreAction((context, payload) => {
    let uid = context.rootState.User.currentUser.uid;
    let checkout_session = payload.checkout_session;
    // const stripeRef = DB.collection('projects').doc(project_id).collection('stripe')
    context.bindFirestoreRef('stripe_checkout_session', DB.collection('users').doc(uid).collection('stripe').doc('items').collection('checkout_sessions').doc(checkout_session));
  }),
  updateFbWithLatestStripeInstance: firestoreAction((context) => {
    let project_id = context.rootState.Admin.project_id
    var data = {
      project_id: project_id,
    }
    const updateFbWithLatestStripeInstance = Functions.httpsCallable("updateFbWithLatestStripeInstance")
    return new Promise((resolve, reject) => {
      return updateFbWithLatestStripeInstance(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  setStripeProduct: firestoreAction((context, payload) => { // MOVE THIS TO ADMIN
    let project_id = context.rootState.Admin.project_id
    let p = clean(payload, true)
    var data = {
      project_id: project_id,
      payload: p.data
    }
    const setStripeProduct = Functions.httpsCallable("setStripeProduct")
    return new Promise((resolve, reject) => {
      return setStripeProduct(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  updateActiveStatusOfStripeProduct: firestoreAction((context, payload) => { // MOVE THIS TO ADMIN
    let project_id = context.rootState.Admin.project_id
    var data = {
      project_id: project_id,
      payload: payload
    }
    const updateActiveStatusOfStripeProduct = Functions.httpsCallable("updateActiveStatusOfStripeProduct")
    return new Promise((resolve, reject) => {
      return updateActiveStatusOfStripeProduct(data)
        .then((response) => {
          resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
  setStripeProductLocal: firestoreAction((context, payload) => {
    context.commit('SET_STRIPE_PRODUCT_LOCAL', payload)
  }),
  assignStripeProductLocal: firestoreAction((context, payload) => {
    var product_id = payload.product_id
    var product = context.getters['getStripeProductById'](product_id)
    context.commit('SET_STRIPE_PRODUCT_LOCAL', product)
  }),
  resetStripeProductLocal: firestoreAction((context) => {
    context.commit('SET_STRIPE_PRODUCT_LOCAL', {})
  }),
  createCheckoutSession: firestoreAction(async (context, payload) => {
    const project_id = context.rootState.Admin.project_id
    try {
      const { price, product } = payload;
      console.log(price)
      const price_id = price.id;
      const price_unit_amount = price.price_unit_amount;
      const price_type = price.type;
      const product_id = product.id;
      const product_description = product.description;
      const uid = context.rootState.User.currentUser.uid;
      const session_data = {
        // required
        success_url: `${window.location.origin}/${(context.rootGetters['Router/getState']('is_private_label') == false) ? (project_id + '/') : ''}donate/success?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/${(context.rootGetters['Router/getState']('is_private_label') == false) ? (project_id + '/') : ''}donate/failed`,
        line_items: [{ price: payload.price.id, quantity: 1 }],
        mode: (price.type === 'recurring') ? 'subscription' : 'payment',
        // optional
        metadata: {
          uid,
          product_id,
          product_description,
          price_id,
          price_type,
          price_unit_amount,
          project_id,
        }
      }
      await context.dispatch('Functions/callFunction', {
        function_name: 'firekitOnCallFirestoreSwitch', function_payload: {
          action: 'add',
          path: `users/${uid}/checkout_sessions`,
          payload: session_data
        }
      }, { root: true });
      const snapshot = await DB.collection('users').doc(uid).collection('checkout_sessions')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get();
      if (snapshot.empty) {
        console.log('No matching document found.');
        return;
      }
      let lastCheckoutSessionDocId;
      snapshot.forEach(doc => {
        lastCheckoutSessionDocId = doc.id
      });
      return context.bindFirestoreRef('stripe_checkout_session', DB.collection('users').doc(uid).collection('checkout_sessions').doc(lastCheckoutSessionDocId));
    } catch (e) {
      console.error(e)
    }
  }),
  subscribe: async (context) => {
    const project_secrets = context.rootGetters['Project/getState']('project_secrets');
    console.log(project_secrets)

    const stripe_secrets = project_secrets.find(secret => secret.id === 'stripe'); // assumes only one stripe secret
    const stripe_publishable_key = stripe_secrets?.publishable_key;
    try {
      const stripe = await loadStripe(stripe_publishable_key);
      const stripe_checkout_session = context.state.stripe_checkout_session;
      const sessionId = stripe_checkout_session.session.id;
      return stripe.redirectToCheckout({
        sessionId
      })
    } catch (e) {
      console.error(e)
    }
  },
}

const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  SET_STRIPE_PRODUCTS: (state, payload) => {
    state.stripe_products = payload
  },
  SET_STRIPE_PRODUCT_LOCAL: (state, payload) => {
    state.stripe_product_local = payload
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
