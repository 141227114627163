// src/mixins/appMixin.js
import {
  MatchMediaBreakpoint
} from 'match-media-breakpoint';

const breakpoints = [{
    breakpoint: 'mobile',
    width: 0
  },
  {
    breakpoint: 'tablet',
    width: 768
  },
  {
    breakpoint: 'desktop',
    width: 1024
  },
  {
    breakpoint: 'widescreen',
    width: 1216
  },
  {
    breakpoint: 'fullhd',
    width: 1408
  },
];

const matchMediaBreakpoint = new MatchMediaBreakpoint({
  breakpoints,
  onBreakpointChange: (currentBreakpoint, previousBreakpoint) => {
    // console.log({
    //   currentBreakpoint,
    //   previousBreakpoint
    // });
  },
});

export default {
  name: 'AppMixin',
  data() {
    return {

    }
  },
  created() {
    this.handleMediaBreakpointChange(matchMediaBreakpoint.value)
    // Add the callback function as a listener to the query list.
    matchMediaBreakpoint.subscribe(this.handleMediaBreakpointChange) // add listener. The hander will be called with the previous and current breakpoint as parameters at each breakpoint change
    // matchMediaBreakpoint.subscribe(this.handleZoomBreakpointChange) // add listener. The hander will be called with the previous and current breakpoint as parameters at each breakpoint change
  },
  mounted() {
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    this.handleOrientationChange(mediaQueryList)
    mediaQueryList.onchange = (e) => {
      this.handleOrientationChange(e)
    }
  },
  computed: {
    project_id: {
      get: function() {
        return this.$store.getters['Admin/getProjectId']
      },
      // setter
      set: function(value) {
        this.$store.dispatch('Admin/setProjectId', value)
      }
    },
    projectSettingsAreLoaded() {
      return this.$store.getters['Settings/getProjectSettingsAreLoaded']
    },

    landscape() {
      const orientation = this.$store.getters['Admin/getOrientation']
      if (orientation === "portrait-primary") {
        return false
      } else {
        return true
      }
    },
    device() {
      return this.$store.getters['Admin/getDevice']
    },
    componentKey: {
      get: function() {
        return this.$store.getters['Admin/getComponentKey']
      },
      // setter
      set: function(value) {
        this.$store.dispatch('Admin/setComponentKey', value)
      }
    },
  },
  methods: {
    async getCustomDomainProjectId() {
      var self = this
      return new Promise((resolve, reject) => {
        async function getProjectIdByDomain(domain){

          return self.$store.dispatch('Settings/getProjectIdByDomain', { domain: domain }).then(r => {
            // console.log(r)
            return r
          })
        }
        // const domain = 'http://localhost:8080'
        try {
          const domain = window.location.origin
          resolve(getProjectIdByDomain(domain))
        } catch(e){
          reject(e)
        }
      })
    },
    // Define a callback function for the event listener.
    handleOrientationChange(evt) {
      var payload = {}
      //
      if (evt.matches) {
        /* The viewport is currently in portrait orientation */
        payload.orientation = 'portrait-primary'
      } else {
        /* The viewport is currently in landscape orientation */
        payload.orientation = 'landscape-primary'
      }
      this.$store.dispatch('Admin/handleOrientationChange', payload)
    },
    handleMediaBreakpointChange(currentBreakpoint) {
      var payload = {
        breakpoint: currentBreakpoint
      }
      this.$store.dispatch('Admin/handleMediaBreakpointChange', payload)
    },
  }
}
