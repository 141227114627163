module.exports = function (db, path) {
    const parts = path.split('/');
    let ref = db;

    for (let i = 0; i < parts.length; i++) {
        if (i % 2 === 0) {
            ref = ref.collection(parts[i]);
        } else {
            ref = ref.doc(parts[i]);
        }
    }

    return ref;
}