/* algolia.store.js */
import Vue from 'vue'
import algoliasearch from 'algoliasearch/lite';
import { sort } from 'fast-sort'

const initialState = () => ({
  search_client: null,
  algolia_index: null,
  algolia_search_results: [],
  is_directory_map_result_highlighted: false,
  last_google_map_marker_clicked: null
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getState: (state) => (key) => {
    return state[key]
  },
}
// Actions
const actions = {
  setState: (context, payload) => {
    context.commit('SET_STATE', payload);
  },
  reset({
    commit
  }) {
    commit('RESET')
  },
  bindAlgoliaSearchKey: (context, payload) => {
    let visible_by_value = null;
    let index_name = null;
    let project_id = context.rootState.Admin.project_id;
    const project = context.rootGetters['Router/getState']('project');
    if (payload.demo) {
      index_name = project && Object.prototype.hasOwnProperty.call(project, 'algolia_index') && project.algolia_index ? project.algolia_index : 'demo_users';
      // search_attributes.visible_by = `group/demoAll`
      visible_by_value = `group/demoAll`;
      context.dispatch('Admin/setIsProjectDemo', true, { root: true })
    } else {
      index_name = project && Object.prototype.hasOwnProperty.call(project, 'algolia_index') && project.algolia_index ? project.algolia_index : 'prod_users';
      visible_by_value = `group/${project_id}All`;
      context.dispatch('Admin/setIsProjectDemo', false, { root: true })
    }
    context.dispatch('setState', { key: 'algolia_index', value: index_name });

    context.dispatch('Functions/callFunction', { function_name: 'callAlgoliaAction', function_payload: { action: 'get_temporary_api_key', visible_by: visible_by_value, index_name: index_name } }, { root: true }).then(response => {
      console.log(response)
      let searchClient = algoliasearch(
        process.env.VUE_APP_ALGOLIA_SEARCH_APP_ID,
        response.data
      )
      context.commit('SET_STATE', { key: 'search_client', value: searchClient });
    })
  },

  reorderAlgoliaSearchResult: (context, payload) => {
    const results = context.state.algolia_search_results;
    const item_to_move = payload.item_to_move;
    const new_array_position = 0;
    var old_array_position = results.map(function (x) { return x.uid; }).indexOf(item_to_move.uid);

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    }
    const new_results = array_move(results, old_array_position, new_array_position);
    context.dispatch('setState', { key: 'algolia_search_results', payload: new_results })
    // console.log(new_results);
  },
  downloadCSVData: (context, payload) => {
    let download_options = payload;
    let user_fields = context.rootState.Settings.user_fields.filter(a => a.bulkImport).map(e => e.id);
    if (download_options.group_field) {
      user_fields.unshift(download_options.group_field);
    }
    let formatted_user_fields = [];
    user_fields.map(e => {
      if (e === 'address') {
        formatted_user_fields.push('address_street', 'address_suite', 'address_city', 'address_state', 'address_zipcode', 'address_country')
      } else if (e === 'professional') {
        formatted_user_fields.push('professional_company', 'professional_title', 'professional_link');
      } else {
        formatted_user_fields.push(e);
      }
    })

    let algolia_search_results = context.state.algolia_search_results;

    if (!download_options.include_inactive) {
      algolia_search_results = algolia_search_results.filter(e => {
        if (e.status === 'active') {
          return e;
        }
      })
    }

    algolia_search_results = sort(algolia_search_results).by([
      { desc: u => u[download_options.sort_keys[0]] },
      { asc: u => u[download_options.sort_keys[1]] }
    ]);

    let csv = [];
    if (download_options.type === 'default') {
      csv = formatted_user_fields.join(',') + '\n';
      algolia_search_results.forEach((row) => {
        let fields = [];
        formatted_user_fields.map(f => {
          if (row[f]) {
            fields.push(row[f]);
          } else if (f.startsWith('address') || f.startsWith('professional') || f.startsWith(download_options.group_field)) {
            switch (f) {
              case (download_options.group_field):
                fields.push(row[download_options.sort_keys[0]]);
                break;
              case ('address_street'):
                fields.push(row['address'][0]);
                break;
              case ('address_suite'):
                fields.push(row['address'][1]);
                break;
              case ('address_city'):
                fields.push(row['address'][2]);
                break;
              case ('address_state'):
                fields.push(row['address'][3]);
                break;
              case ('address_zipcode'):
                fields.push(row['address'][4]);
                break;
              case ('address_country'):
                fields.push(row['address'][5]);
                break;
              case ('professional_company'):
                fields.push(row['professional'][0]);
                break;
              case ('professional_title'):
                fields.push(row['professional'][1]);
                break;
              case ('professional_link'):
                fields.push(row['professional'][2]);
                break;
              default:
                fields.push('')
            }
          } else {
            fields.push('');
          }
        })
        // console.log(row)
        csv += fields.join(',');
        csv += "\n";
      });
    } else if (download_options.type === 'shutterfly') {
      let shutterfly_fields = ['First Name', 'Last Name', 'Display/Household Name (Smith Family)', 'Address Line 1', 'Address Line 2', 'City', 'State', 'Postal Code', 'Country', 'Email'];
      if (download_options.group_field) {
        shutterfly_fields.unshift(download_options.group_field);
      }
      csv = shutterfly_fields.join(',') + '\n';
      algolia_search_results.forEach((row) => {
        let fields = [];
        shutterfly_fields.map((f) => {
          switch (f) {
            case (download_options.group_field):
              fields.push(row[download_options.sort_keys[0]]);
              break;
            case ('First Name'):
              row['first_name'] ? fields.push(row['first_name']) : fields.push('');
              break;
            case ('Last Name'):
              row['last_name'] ? fields.push(row['last_name']) : fields.push('')
              break;
            case ('Display/Household Name (Smith Family)'):
              row['last_name'] ? fields.push(`The ${row['last_name']} Family`) : fields.push('');
              break;
            case ('Address Line 1'):
              row['address'][0] ? fields.push(row['address'][0]) : fields.push('');
              break;
            case ('Address Line 2'):
              row['address'][1] ? fields.push(row['address'][1]) : fields.push('');
              break;
            case ('City'):
              row['address'][2] ? fields.push(row['address'][2]) : fields.push('');
              break;
            case ('State'):
              row['address'][3] ? fields.push(row['address'][3]) : fields.push('');
              break;
            case ('Postal Code'):
              row['address'][4] ? fields.push(row['address'][4]) : fields.push('');
              break;
            case ('Country'):
              row['address'][5] ? fields.push(row['address'][5]) : fields.push('');
              break;
            case ('Email'):
              row['email'] ? fields.push(row['email']) : fields.push('');
              break;
            default:
              fields.push('')
          }
        })
        // console.log(row)
        csv += fields.join(',');
        csv += "\n";
      });
    }

    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    anchor.target = '_blank';
    const project_id = context.rootGetters['Admin/getProjectId'];
    anchor.download = `${project_id}_directory_export.csv`;
    anchor.click();
  },
  setAlgoliaData: (context, payload) => {
    const users = payload.value;
    const project = context.rootGetters['Settings/getState']('project');
    const displayNameArray = project?.user_display_name;
    const userFields = context.rootGetters['Settings/getState']('user_fields');
    const dataRevised = users.map((user) => {
      const formatted_name = Vue.prototype.$formatDisplayName(displayNameArray, user, userFields);
      return {
        ...user, // Spread the original user object
        full_name: formatted_name
      };
    });
    context.commit('SET_STATE', { key: "algolia_search_results", value: dataRevised }); // Commit the revised data
  },

}
// Mutations
const mutations = {
  SET_STATE: (state, payload) => {
    const key = payload.key;
    const value = payload.value;
    state[key] = value
  },
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
