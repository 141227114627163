import Vue from 'vue'
import VueRouter from 'vue-router'
import white_label_routes from './routes/white_label_routes'
import private_label_routes from './routes/private_label_routes'
import { Magic } from 'magic-sdk';
const m = new Magic(process.env.VUE_APP_MAGIC_PUBLISHABLE_API_KEY);

import { Auth } from '@/firebase/auth'
/* Shirly */
import store from '@/store'

import handleAppBinding from './helpers/handleAppBinding.js'
import handleAuthRequired from './helpers/handleAuthRequired.js'
import handlePermissionRequired from './helpers/handlePermissionRequired.js'

Vue.use(VueRouter)
const isProduction = process.env.NODE_ENV === 'production' ? true : false;
const customDomain = isProduction ? ((window.location.origin.split('.')[0].includes('app')) ? false : true) : false; // true for dev; fn for production: Vue.prototype.$customDomain = (window.location.origin.split('.')[0].includes('app')) ? false : true
store.dispatch('Router/setState', {
  key: 'is_private_label',
  value: customDomain
});
const domain_type = customDomain ? {
  type: 'private_label',
  routes: 'private_label_routes',
  domain: window.location.origin,
  isCustomDomain: true
} : {
  type: 'white_label',
  routes: 'white_label_routes',
  domain: window.location.origin,
  isCustomDomain: false
}


const all_routes = {
  private_label_routes: private_label_routes,
  white_label_routes: white_label_routes,
}

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: "is-active",
  base: process.env.BASE_URL,
  routes: all_routes[domain_type['routes']]
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Array.prototype.contains = function (obj) {
  var i = this.length;
  while (i--) {
    if (this[i] === obj) {
      return true;
    }
  }
  return false;
}


function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}


function getCookies() {
  const cookieArray = document.cookie.split('; ');
  const cookieObject = {};

  cookieArray.forEach(cookie => {
    const [key, value] = cookie.split('=');
    cookieObject[key] = value;
  });

  return cookieObject;
}

async function attemptLogin(did_token) {
  console.log(did_token)
  try {
    const decision = await store.dispatch('User/login', { supplied_did_token: did_token });
    if (decision !== 'failed') {
      return Auth.currentUser;
    }
  } catch (e) {
    console.error("Error during login:", e);
  }
  return null;
}


function route_to(name, project_id) {
  let push_obj = {};
  push_obj.name = name;
  if (!customDomain) {
    push_obj.project_id = project_id
  }
  return router.push(push_obj)
}

function loading(decision) {
  if (decision) {
    store.dispatch('Modal/setState', {
      key: 'modal',
      value: 'loading'
    });
    store.dispatch('Modal/setState', {
      key: 'is_modal_active',
      value: true
    });
  } else {
    store.dispatch('Modal/setState', {
      key: 'is_modal_active',
      value: false
    });
  }
}

router.beforeEach(async (to, from, next) => {
  let isUserAuthenticated = false;
  loading(true);
  const router_view_wait = 1000;
  let currentUser = Auth.currentUser;
  isUserAuthenticated = currentUser ? true : false;
  const hasWindowLocationSearch = window.location.search && window.location.search.length > 0 && window.location.search !== '' ? true : false;
  if (!currentUser && hasWindowLocationSearch) {
    try {
      const newDidToken = await m.auth.loginWithCredential();
      if (newDidToken) {
        currentUser = await attemptLogin(newDidToken);
      }
      isUserAuthenticated = true;
    } catch (e) {
      console.error("An error occurred:", e);
      isUserAuthenticated = false;
      // Handle specific errors based on their type if needed
    }
  }
  await later(300)
  const domain = window.location.origin;
  const path = to.fullPath

  const is_app_bound = store.getters['Router/getState']('is_app_bound');
  const requires_auth = to.matched.some(record => record.meta.requiresAuth);
  const requires_permission = to.matched.some(record => record.meta.requiresPermission);
  if (!is_app_bound) { // if both settings and app have not been bound, go through this flow;
    store.dispatch('Router/setState', {
      key: 'current_user',
      value: currentUser
    }); // set store
    store.dispatch('Router/setState', {
      key: 'domain',
      value: domain
    }); // set store
    store.dispatch('Router/setState', {
      key: 'path',
      value: path
    }); // set store
    const bind_project_result = await handleAppBinding([true, false, false]); // bind project only
    if (bind_project_result !== 'okay' && bind_project_result !== 'continue') {
      switch (bind_project_result) {
        case ('redirect'):
          (currentUser && !customDomain) ? route_to('Projects') : route_to('Login');
          await later(router_view_wait);
          store.dispatch('Admin/setRouterViewIsReady', true);
          loading(false);
          break;
        case ('magic_login'):
          if (currentUser && !customDomain) {
            route_to('Projects')
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          } else if (currentUser && customDomain) {
            const bind_settings_result = await handleAppBinding([false, true, false]); // bind settings only
            await later(router_view_wait);
            const bind_app_result = await handleAppBinding([false, false, true]); // bind app only
            const arr = [bind_settings_result, bind_app_result];
            const allEqual = arr => arr.every(val => val === arr[0]);
            const binding_result = allEqual(arr) ? 'equal' : 'not all equal';
            if (binding_result === 'equal') {
              const settings_are_ready = store.getters['Router/getState']('settings_are_ready');
              const app_is_ready = store.getters['Router/getState']('app_is_ready');
              if (settings_are_ready && app_is_ready) {
                store.dispatch('Router/setState', {
                  key: 'is_app_bound',
                  value: true
                })
              } else {
                store.dispatch('Router/setState', {
                  key: 'is_app_bound',
                  value: false
                })
              }
              // console.log('routing again!')
              route_to('Home');
              await later(router_view_wait);
              store.dispatch('Admin/setRouterViewIsReady', true);
              loading(false);
            }
          }
          break;
        default:
          if (requires_auth && !currentUser) {
            route_to('Login');
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          } else {
            next();
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          }
      }
      store.dispatch('Admin/setRouterViewIsReady', true);
      store.dispatch('Admin/setState', {
        key: 'isLoadingModalActive',
        value: false
      });
    } else {
      const auth_can_proceed = currentUser ? (await handleAuthRequired(currentUser, requires_auth)) : false;
      if (!auth_can_proceed) {
        route_to('Login');
        await later(router_view_wait);
        store.dispatch('Admin/setRouterViewIsReady', true);
        store.dispatch('Admin/setState', {
          key: 'isLoadingModalActive',
          value: false
        });
      } else {
        const bind_settings_result = await handleAppBinding([false, true, false]); // bind settings only
        // await later(router_view_wait);
        const bind_app_result = await handleAppBinding([false, false, true]); // bind app only
        const arr = [bind_settings_result, bind_app_result];
        const allEqual = arr => arr.every(val => val === arr[0]);
        const binding_result = allEqual(arr) ? [...new Set(arr)][0] : 'not all equal';
        if (binding_result === 'okay' || binding_result === 'continue') {
          const settings_are_ready = store.getters['Router/getState']('settings_are_ready');
          const app_is_ready = store.getters['Router/getState']('app_is_ready');
          if (settings_are_ready && app_is_ready) {
            store.dispatch('Router/setState', {
              key: 'is_app_bound',
              value: true
            })
          } else {
            store.dispatch('Router/setState', {
              key: 'is_app_bound',
              value: false
            })
          }
          const permission_can_proceed = await handlePermissionRequired(requires_permission);
          if (permission_can_proceed) {
            next()
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          } else {
            store.dispatch('Admin/openToast', {
              toast: {
                duration: 4000,
                message: `User does not have permission.`,
                position: 'is-top',
                type: 'is-warning'
              }
            })
            next({
              name: 'Home'
            })
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          }
        }
        store.dispatch('Admin/setRouterViewIsReady', true);
        await later(router_view_wait);
        loading(false);
        store.dispatch('Admin/forceRerender');
      }
    }
  } else {
    Auth.onAuthStateChanged(async (auth_user) => {
      if (auth_user) {
        const auth_can_proceed = auth_user ? (await handleAuthRequired(currentUser, requires_auth)) : false;
        const permission_can_proceed = await handlePermissionRequired(requires_permission);
        if (auth_can_proceed) {
          if (permission_can_proceed) {
            next()
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          } else {
            store.dispatch('Admin/openToast', {
              toast: {
                duration: 4000,
                message: `User does not have permission.`,
                position: 'is-top',
                type: 'is-warning'
              }
            })
            await later(router_view_wait);
            store.dispatch('Admin/setRouterViewIsReady', true);
            loading(false);
          }
        } else {
          next()
          await later(router_view_wait);
          store.dispatch('Admin/setRouterViewIsReady', true);
          loading(false);
        }
      } else {
        next()
        await later(router_view_wait);
        store.dispatch('Admin/setRouterViewIsReady', true);
        loading(false);
      }
    });
  }
  await later(router_view_wait);
  store.dispatch('Admin/setRouterViewIsReady', true);
  loading(false);
})

export const Router = router;
export const DomainType = domain_type;
export {
  later
};
