/* mails.store.js */
import {
  firestoreAction
} from 'vuexfire'
import {
  DB
} from '@/firebase/db'
import {
  Functions
} from '@/firebase/functions'

// import the auto exporter

const initialState = () => ({
  project_mail: [],
})
// State object
const state = initialState()
// Getter functions
const getters = {
  getProjectMail: state => state.project_mail,
}
// Actions
const actions = {
  bindMailRefForProject: firestoreAction(async(context) => {
    return new Promise((resolve) => {
    let project_id = context.rootState.Admin.project_id
      context.bindFirestoreRef('project_mail', DB.collection('projects').doc(project_id).collection('mail'))
      resolve(context.state.project_mail)
    })
  }),
  projectMailAction: firestoreAction(async(context, payload) => {
    const action = payload.action
    const mail_data = payload.mail_data
    const mail_addressees = payload.mail_addressees
    let project_id = context.rootState.Admin.project_id
    let current_user_token = await context.dispatch('User/getCurrentUserToken', {}, {root:true})
    var data = {
      project_id: project_id,
      mail_addressees: mail_addressees,
      action: action, // add, update, delete
      mail_data: mail_data, //update and delete objects must contain document id
      current_user_token: current_user_token
    }
    console.log(data)
    const projectMailAction = Functions.httpsCallable("projectMailAction")
    return new Promise((resolve, reject) => {
      return projectMailAction(data)
        .then((response) => {
            resolve(response);
        }, error => {
          console.error(error)
          // http failed, let the calling function know that action did not work out
          reject(error);
        })
    })
  }),
}
// Mutations
const mutations = {
  RESET: state => {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
